import Config from '../constants/config'
import {
  EMAIL_VALIDATION_REGEX,
  API_DATA,
  STATUS_BAR_TYPES,
  TEST_TYPE_GOALS,
  TEST_TYPE_TRAITS,
  TEST_TYPE_POTENTIALS,
  ENTITIES,
  TEST_TYPE_LATERAL,
  TEST_TYPE_POTENTIALS_CROSS,
  BOOKING_KEYS,
  BREAKPOINT_MOBILE,
  GERMAN_ZIP_REGEX,
  NUMBERS_ONLY_REGEX,
  loginMessages,
  LANG_CODES,
  DEFAULT_LANGUAGE,
  LANGUAGE_NAMES,
  languageSortingOrder,
  REPORT_MODULES,
  CUSTOMER_VISIBILITY_STATUS
} from '../constants/constants'
import 'react-datepicker/dist/react-datepicker.css'
import sessionHandler from './sessionHandler'
import { PAGES } from '../constants/pages'
import { secondsToHms } from './dateTimeHelper'
import reactStringReplace from 'react-string-replace'
import ReactTooltip from 'react-tooltip'
import { Process } from '../../entities/Process'
import { Assessment } from '../../entities/Assessment'
import { Participant } from '../../entities/Participant'
import { PT } from '../../entities/Pt'
import { Contact } from '../../entities/Contact'
import { Result } from '../../entities/Result'
import { CreditBooking } from '../../entities/CreditBooking'
import { TestConfig } from '../../entities/TestConfig'
import { Screen } from '../../entities/Screen'
import { Reminder } from '../../entities/Reminder'
import { ParticipantMailLog } from '../../entities/ParticipantMailLog'
import { PtInvitationTemplate } from '../../entities/PtInvitationTemplate'
import { CustomersFile } from '../../entities/CustomersFile'
import { Invoice } from '../../entities/Invoice'

export function isString(value) {
  return typeof value === 'string' || value instanceof String
}

export const getApiUrl = (endPoint) => {
  return `${Config.baseUrl}/${Config.prefix}/${Config.version}/${Config.suffix}/${endPoint}`
}

export const getProcessSelectOptions = ({ processList, allowEmpty = false, t }) => {
  const processSelectOptions = []

  processList.map((process) => {
    const canEdit = process.isEditor()
    const testCount = process.relatedAssessments.length
    const entityLabel = getEntityLabel(ENTITIES.assessments, testCount)
    const noCapabilitiesNotice = !canEdit ? `[${t('noWritePermissions')}]` : ''
    const isInArchiveNotice = !process.isVisible() ? `[${t('inTheArchive')}]` : ''
    const isDisabled = (testCount === 0 && !allowEmpty) || !canEdit || !process.isVisible()

    return processSelectOptions.push({
      label: `${process.processName} (${testCount} ${entityLabel}) ${noCapabilitiesNotice} ${isInArchiveNotice}`,
      value: process.processName,
      uuid: process.processUuid,
      disabled: isDisabled
    })
  })

  return processSelectOptions
}

export const getPtLink = (referenceToken) => `${Config.elektryonUrl}/?token=${referenceToken}`
export const getHubLink = (pnrHash) => `${Config.elektryonUrl}/?hub=${pnrHash}`

export const getPtCount = (assessments) => {
  const visiblePts = assessments.map((ass) => ass.relatedPts.filter((pt) => pt.isVisible())?.length)
  const archivedPts = assessments.map((ass) => ass.relatedPts.filter((pt) => pt.isArchived())?.length)
  return {
    visible: visiblePts.reduce((prev, curr) => prev + curr, 0),
    archived: archivedPts.reduce((prev, curr) => prev + curr, 0)
  }
}

export const getParticipantCount = (assessments) => {
  const allParticipants = []

  assessments.forEach((assessment) => {
    assessment.relatedPts.forEach((relatedPt) => {
      if (relatedPt.relatedParticipant) {
        allParticipants.push(relatedPt.relatedParticipant)
      }
    })
  })

  const seen = new Set()
  const uniqueParticipants = allParticipants.filter((participant) => {
    const duplicate = seen.has(participant.pNr)
    seen.add(participant.pNr)
    return !duplicate
  })

  return {
    visible: uniqueParticipants.filter((p) => p.isVisible()).length,
    archived: uniqueParticipants.filter((p) => p.isArchived()).length
  }
}

export const mailIsValid = (mail) => {
  if (EMAIL_VALIDATION_REGEX.test(mail)) return true
  return false
}

export const mailIsUnique = (mail, isEditMode) => {
  const suffix = isEditMode ? '/edit' : ''
  const url = getApiUrl(API_DATA.contacts.endPointCheckMail) + suffix
  const payload = { contactEmail: mail }
  const requestOptions = getRequestOptions(payload)

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .then((responseData) => responseData.response.available)
    .catch((e) => console.error(e))
}

export const getRequestOptions = (payload) => ({
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionHandler.getSessionId()}`
  },
  body: JSON.stringify(payload),
  crossDomain: true
})

export const handleFetchErrors = async (response, context) => {
  if (!response.ok && !hasAuthError(response.status)) {
    return response.json().then((err) => {
      if (err?.response?.errorMessage) {
        console.error(err.response.errorMessage)
      }
      throw err
    })
  }
  if (hasAuthError(response.status) && sessionHandler.getSessionId()) {
    context.setLoginMessage(loginMessages.expelled)
    sessionHandler.logout(context)
  }
  return response.json()
}

export const hasAuthError = (statusCode) => [401, 403].includes(statusCode)

export const updateEntities = (updates, context) => {
  console.log({ updates })
  context.setCompleteDataSet((prevState) => {
    const newState = { ...prevState }

    // Collect updated and new elements because we need to add related elements later
    const elements = []

    for (const [entityType, entityUpdates] of Object.entries(updates)) {
      if (!Array.isArray(entityUpdates) || entityUpdates.length === 0) {
        continue
      }

      const existingEntitiesMap = new Map(
        prevState[entityType].map((entity) => [getCombinedKey(entity, entityType), entity])
      )

      // Separate updates into existing and new entities
      const { existingElements, newElements } = updates[entityType].reduce(
        (acc, update) => {
          const combinedKey = getCombinedKey(update, entityType)
          if (existingEntitiesMap.has(combinedKey)) {
            acc.existingElements.push(update)
          } else {
            acc.newElements.push(update)
          }
          return acc
        },
        { existingElements: [], newElements: [] }
      )

      // Update existing elements
      newState[entityType] = prevState[entityType]
        .map((entity) => {
          const combinedKey = getCombinedKey(entity, entityType)
          const elem = existingElements.find((update) => getCombinedKey(update, entityType) === combinedKey)
          if (elem) {
            const newEntity = createEntity(entityType, elem)
            elements.push(newEntity)
            return newEntity
          }
          return entity
        })
        .filter((entity) => entity?.visibility !== CUSTOMER_VISIBILITY_STATUS.deleted)

      // Add new elements to the state
      const newEntities = newElements
        .map((newElem) => {
          const newEntity = createEntity(entityType, newElem)
          elements.push(newEntity)
          return newEntity
        })
        .filter((newElem) => newElem?.visibility !== CUSTOMER_VISIBILITY_STATUS.deleted)

      newState[entityType] = [...newEntities, ...newState[entityType]]
    }

    elements
      .filter((entity) => entity?.visibility !== CUSTOMER_VISIBILITY_STATUS.deleted)
      .forEach((element) => {
        switch (element.entity) {
          case 'Process': {
            element.addRelatedElements(newState)
            element.relatedAssessments.forEach((ass) => {
              ass.addRelatedElements(newState)
              ass.relatedPts.forEach((pt) => {
                pt.addRelatedElements(newState)
                pt.relatedParticipant.addRelatedElements(newState)
              })
            })
            break
          }
          case 'Assessment': {
            element.addRelatedElements(newState)
            element.relatedProcess.addRelatedElements(newState)
            element.relatedPts.forEach((pt) => {
              pt.addRelatedElements(newState)
              pt.relatedParticipant.addRelatedElements(newState)
            })
            break
          }
          case 'Participant': {
            element.addRelatedElements(newState)
            element.ptList.forEach((pt) => {
              pt.addRelatedElements(newState)
              pt.relatedAssessment.addRelatedElements(newState)
              pt.relatedProcess.addRelatedElements(newState)
            })
            break
          }
          case 'PT': {
            element.addRelatedElements(newState)
            element.relatedAssessment.addRelatedElements(newState)
            element.relatedProcess.addRelatedElements(newState)
            element.relatedParticipant.addRelatedElements(newState)
            break
          }
          case 'Result': {
            const pt = newState.pts.find((pt) => pt.resultNr === element.resultNr)
            if (pt) {
              pt.addRelatedElements(newState)
              const participant = newState.participants.find((p) => p.pNr === pt.pNr)
              participant.addRelatedElements(newState)
            }
            break
          }
          case 'ParticipantMailLog': {
            element.addRelatedElements(newState)
            const participant = newState.participants.find((p) => p.pNr === element.pNr)
            participant.addRelatedElements(newState)
            break
          }
        }
      })

    // Update assessment and process after PT delete
    elements.forEach((element) => {
      if (element?.visibility === CUSTOMER_VISIBILITY_STATUS.deleted && element.entity === 'PT') {
        element.addRelatedElements(newState)
        if (element.relatedAssessment) {
          element.relatedAssessment.addRelatedElements(newState)
        }
      }
    })

    return newState
  })
}

const getCombinedKey = (entity, entityType) => {
  const key = entity[API_DATA[entityType].idKey]
  const keySecondary = entity[API_DATA[entityType].idKeySecondary] || ''
  return `${key}-${keySecondary}`
}

const createEntity = (entityType, element) => {
  switch (entityType) {
    case 'processes':
      return new Process(element)
    case 'assessments':
      return new Assessment(element)
    case 'participants':
      return new Participant(element)
    case 'pts':
      return new PT(element)
    case 'contacts':
      return new Contact(element)
    case 'results':
      return new Result(element)
    case 'creditBookings':
      return new CreditBooking(element)
    case 'usedConfigs':
      return new TestConfig(element)
    case 'customScreens':
      return new Screen(element)
    case 'reminders':
      return new Reminder(element)
    case 'participantMailLogs':
      return new ParticipantMailLog(element)
    case 'ptInvitationTemplates':
      return new PtInvitationTemplate(element)
    case 'customersFiles':
      return new CustomersFile(element)
    case 'invoices':
      return new Invoice(element)
    default:
      return element
  }
}

export const getEntityLabel = (entity, count) => {
  switch (entity) {
    case ENTITIES.participants:
    case 'participant-select-for-email':
      return count === 1 ? 'participant' : 'participants'
    case ENTITIES.processes:
      return count === 1 ? 'process' : 'processes'
    case ENTITIES.users:
      return count === 1 ? 'user' : 'users'
    case ENTITIES.assessments:
    case ENTITIES.configs:
    case 'test-select-for-reminder':
      return count === 1 ? 'Test' : 'Tests'
    case ENTITIES.pts:
      return count === 1 ? 'pt' : 'pts'
    case ENTITIES.bookings:
      return count === 1 ? 'booking' : 'bookings'
    case ENTITIES.credits:
      return count === 1 ? 'Credit' : 'Credits'
    case ENTITIES.tans:
      return count === 1 ? 'TAN' : 'TANs'
    case ENTITIES.accessData:
    case ENTITIES.accessDataHub:
      return 'accessData'
    case ENTITIES.ptResults:
      return count === 1 ? 'result' : 'results'
    case ENTITIES.emails:
    case ENTITIES.participantMailLogs:
      return count === 1 ? 'email' : 'emails'
    case ENTITIES.invoices:
      return count === 1 ? 'invoice' : 'invoices'
    case ENTITIES.reminders:
      return count === 1 ? 'reminder' : 'reminders'
    case 'days':
      return count === 1 ? 'day' : 'days'
    case 'hours':
      return count === 1 ? 'hour' : 'hours'
    default:
      return count === 1 ? 'entry' : 'entries'
  }
}

export const hasSpecialChars = (string) => {
  const SPECIAL_CHARS = ['#', '$', '%', '&', '~', '_', '^', '\\', '{', '}', '/']
  return SPECIAL_CHARS.some((char) => string.includes(char))
}

export const fetchData = async (payload = {}, endPoint, context, errorMsg, showLoadingMessage = true) => {
  const requestOptions = getRequestOptions(payload)
  const url = getApiUrl(endPoint)

  if (showLoadingMessage) {
    setStatusBar({
      controller: context.statusBarController,
      type: STATUS_BAR_TYPES.loading,
      text: 'processingInProgress',
      setVisible: true
    })
  }

  try {
    const response = await fetch(url, requestOptions)
    const responseData = await handleFetchErrors(response, context)
    return responseData
  } catch (e) {
    console.error(e)
    setStatusBar({
      controller: context.statusBarController,
      type: STATUS_BAR_TYPES.error,
      text: errorMsg || 'dataLoadError',
      setVisible: true
    })
  }
}

export const setStatusBar = ({ controller, type, text, setVisible }) => {
  controller.setStatusBarType(type)
  controller.setStatusBarContent(text)
  if (setVisible) {
    controller.setStatusBarVisible(true)
  }
  if (setVisible === false) {
    controller.setStatusBarVisible(false)
  }
}

export const checkDemoStatus = (parentConfig, configUuid) => {
  if (parentConfig) {
    const config = parentConfig.availableConfigs.find((config) => config.configUuid === configUuid)
    return config && config.isDemo()
  }
  return false
}

export const getAverage = (nums) => {
  const filteredNums = nums.filter((num) => !isNaN(num))
  const n = filteredNums.length

  if (n === 0) {
    return ''
  }

  const average = filteredNums.reduce((a, b) => a + b, 0) / n
  const averageRounded = average.toFixed(2)

  return averageRounded
}

export const isPotentials = (configType) => configType === TEST_TYPE_POTENTIALS
export const isTraits = (configType) => configType === TEST_TYPE_TRAITS
export const isGoals = (configType) => configType === TEST_TYPE_GOALS
export const isPotentialsCrossTest = (configType) => configType === TEST_TYPE_POTENTIALS_CROSS
export const isLateral = (configType) => configType === TEST_TYPE_LATERAL
export const isTraitsOrGoals = (configType) => [TEST_TYPE_TRAITS, TEST_TYPE_GOALS].includes(configType)

export const setState = (setData, fieldName, value) => {
  setData((prevData) => ({
    ...prevData,
    [fieldName]: value
  }))
}

export const getDurationString = (duration, t) => {
  const durationRounded = Math.floor(duration / 600) * 600

  const minDur = secondsToHms(durationRounded / 2)
  const maxDur = secondsToHms(durationRounded)

  const stringLong = durationRounded < 300 ? `< 5 ${t('minutesShort')}` : `${minDur} - ${maxDur} ${t('hours')}`
  const stringShort =
    durationRounded < 300 ? `< 5 ${t('minutesShort')}` : `${minDur} - ${maxDur}${'\u00A0'}${t('hoursShort')}`

  return {
    long: stringLong,
    short: stringShort
  }
}

export const getTestTypeName = (testType) => {
  if (isPotentials(testType)) return 'performanceTest'
  if (isTraits(testType)) return 'personalityTest'
  if (isGoals(testType)) return 'interestTest'
  if (isLateral(testType)) return 'lateralLeadership'
  if (isPotentialsCrossTest(testType)) return 'crosstest'
  return 'aptitudeTest'
}

export const getArchivedClassName = (entity) => {
  if (objectIsEmpty(entity)) {
    return ''
  }
  if (entity.isArchived()) return 'archived'
  return ''
}

export const checkOverflow = (element) => element.scrollHeight > element.clientHeight

export const getFormattedNumber = (number, lang, minFractionDigits = 1) => {
  if (number === null || number === undefined) {
    return ''
  }

  const langCode = LANG_CODES[lang || DEFAULT_LANGUAGE]
  const normalizedInput = number.toString().replace(',', '.')
  const hasDecimal = normalizedInput.includes('.')

  if (isNaN(normalizedInput)) {
    return number
  }

  const nf = new Intl.NumberFormat(langCode, {
    minimumFractionDigits: hasDecimal ? minFractionDigits : 0,
    maximumFractionDigits: 2
  })
  return nf.format(normalizedInput)
}

export const getFormattedPrice = (price, lang, numberDigits) => {
  const langCode = LANG_CODES[lang || DEFAULT_LANGUAGE]
  const nf = new Intl.NumberFormat(langCode, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: numberDigits,
    maximumFractionDigits: numberDigits
  })
  return nf.format(price)
}

export const getTotalTestPrice = (assessment, config) => {
  if (config.isDemo()) return 0
  if (assessment?.testCredits) return assessment.testCredits
  if (assessment?.testCredits === 0) return 0

  const configPrice = config.configCreditsCost || 0

  if (!assessment?.reportModules) return configPrice

  const modules = Object.entries(assessment.reportModules)
  const chosenModules = modules.filter((module) => module[1] === 1)
  const chosenModuleKeys = chosenModules.map((chosenModule) => chosenModule[0])
  const modulePrices = chosenModuleKeys.map((key) => REPORT_MODULES.find((RM) => RM.androKey === key).price)
  const modulePriceSum = modulePrices.reduce((prev, curr) => prev + curr, 0)

  return configPrice + modulePriceSum
}

export const getMaximumCreditSum = (assessmentUuids, context, participantCount) => {
  const prices = context.completeDataSet.assessments
    .filter((ass) => assessmentUuids.includes(ass.assessmentUuid))
    .map((ass) => {
      const config = context.completeDataSet.usedConfigs.find((usedConfig) => usedConfig.configUuid === ass.configUuid)
      return getTotalTestPrice(ass, config) * participantCount
    })

  return prices.reduce((prev, curr) => prev + curr, 0)
}

export const getConfigStyle = (config) => {
  if (isTraits(config.configType))
    return {
      color: 'var(--traits-color)',
      icon: 'svg-icon icon-traits'
    }

  if (isGoals(config.configType))
    return {
      color: 'var(--goals-color)',
      icon: 'svg-icon icon-goals'
    }

  return {
    color: 'var(--potentials-color)',
    icon: 'svg-icon icon-potentials'
  }
}

export const fetchUpdates = async (context) => {
  const responseData = await fetchData({}, 'update', context, null, false)

  try {
    updateCompleteDataSet(responseData, context)
  } catch (e) {
    console.error(e)
  }
}

const updateCompleteDataSet = (responseData, context) => {
  const entities = Object.keys(responseData.response.data)
  if (entities.length > 0) {
    updateEntities(responseData.response.data, context)
  }
}

export const isNewElement = (index) => index === -1

export const getLoggedInContact = (completeDataSet) => {
  return completeDataSet.contacts.find((contact) => contact.loggedInContact)
}

export const translateNotifications = (notifications, language) => {
  notifications.forEach((n) => {
    n.localizedContent = n.content[language] || n.content[DEFAULT_LANGUAGE]
    n.localizedTitle = n.title[language] || n.title[DEFAULT_LANGUAGE]
  })
}

export const isLastElement = (index, array) => index === array.length - 1

export const handleSettingsNavBarVisibility = (location, setShowSettingsNavBar) => {
  const firstNavLevel = location.pathname.split('/')[1]

  const isOnSettingsPage = ['settings', 'einstellungen'].includes(firstNavLevel)
  if (isOnSettingsPage) {
    setShowSettingsNavBar(true)
  } else {
    setShowSettingsNavBar(false)
  }
}

export const getResultNr = (ptNumber) => 'RS-' + ptNumber

export const getTableLinkParams = (entity, id, tableName, fieldLabel) => {
  const className = getArchivedClassName(entity)

  const filterParams = {
    field: { value: id, label: fieldLabel || id },
    value: { value: entity[id] },
    tableName: tableName
  }

  return {
    className: className,
    filterParams: filterParams
  }
}

export const isProcess = (entity) => entity === ENTITIES.processes
export const isPt = (entity) => entity === ENTITIES.pts
export const isParticipant = (entity) => entity === ENTITIES.participants
export const isAssessment = (entity) => entity === ENTITIES.assessments
export const isContact = (entity) => entity === ENTITIES.users
export const isReminder = (entity) => entity === ENTITIES.reminders
export const isParticipantMailLog = (entity) => entity === ENTITIES.participantMailLogs

export const downloadResultCSV = (assessmentUuid) => {
  const session = sessionHandler.getSessionId()
  const url = `${Config.baseUrl}/${Config.prefix}/results/test_participants_results_csv/${assessmentUuid}?perseo-session=${session}`
  downloadFileFromUrl(url)
}

export const downloadResultPdfZip = (assessmentUuid, context) => {
  const payload = { assessment_uuid: assessmentUuid }
  const url = `${Config.baseUrl}/${Config.prefix}/results/reports_zip`
  const requestOptions = getRequestOptions(payload)
  downloadReports(url, requestOptions, context)
}

export const isDate = (obj) => Object.prototype.toString.call(obj) === '[object Date]'

export const allContactsAreAdmins = (contacts) => {
  return contacts.filter((contact) => contact.contactIsAdmin).length === contacts.length
}

export const containsSubstring = (string, substring) => string.indexOf(substring) !== -1

export const getGlobalStatusByKey = (statusArray, key) => {
  const option = statusArray?.find((option) => option.statusKey === key) || {}
  return { statusValue: option.statusValue, statusKey: option.statusKey, modified: option.modified }
}

export const getOptionByValue = (optionsArray, value) => {
  if (!Array.isArray(optionsArray)) return {}

  const option =
    optionsArray.find((option) => {
      if (option.value?.from && option.value?.until) {
        return dateRangesAreEqual(option.value, value)
      }
      return option.value === value
    }) || {}

  if (option.value === undefined || option.value === null) {
    return ''
  }

  return { value: option.value, label: option.label }
}

const dateRangesAreEqual = (range1, range2) => {
  return range1.from.getTime() === range2?.from.getTime() && range1.until.getTime() === range2?.until.getTime()
}

export const allParticipantsHaveMailAddress = (participants) => {
  if (!Array.isArray(participants) || !participants) return false
  return participants.every((p) => p.pMail)
}

export const getFlatArray = (arrays) => [].concat.apply([], arrays)

export const getArrayIntersectionByKey = (arrayOfArrays, key) => {
  const keys = arrayOfArrays.map((array) => array.map((val) => val[key]))
  const intersectingKeys = keys.reduce((a, b) => a.filter((c) => b.includes(c)))
  const flatArray = getFlatArray(arrayOfArrays)
  const flatArrayFiltered = flatArray.filter((entry) => intersectingKeys.includes(entry[key]))

  const uniqueIds = []
  const unique = flatArrayFiltered.filter((element) => {
    const isDuplicate = uniqueIds.includes(element[key])
    if (!isDuplicate) {
      uniqueIds.push(element[key])
      return true
    }
    return false
  })

  return unique
}

export const bulkDownloadResultPdfZip = (keysForBulk, entity, context) => {
  const keyId = entity.isPt() ? 'ptList' : 'pList'
  const endpoint = entity.isPt() ? API_DATA.pts.endPointDownloadPdfs : API_DATA.participants.participantReportsZip

  const payload = { [keyId]: keysForBulk }
  const url = getApiUrl(endpoint)
  const requestOptions = getRequestOptions(payload)
  downloadReports(url, requestOptions, context)
}

const downloadReports = (url, requestOptions, context) => {
  const ERROR_CODES = {
    ptListEmpty: 1,
    noFiles: 2
  }
  setStatusBar({
    controller: context.statusBarController,
    type: STATUS_BAR_TYPES.loading,
    text: 'reportCreationInProgress',
    setVisible: true
  })

  fetch(url, requestOptions)
    .then((response) => handleFetchErrors(response, context))
    .then((r) => {
      if (r.response.errorCodes.length === 0) {
        setStatusBar({
          controller: context.statusBarController,
          type: STATUS_BAR_TYPES.success,
          text: 'reportsCreated',
          setVisible: true
        })
        downloadFileFromUrl(r.response.url)
      }
      if (r.response.errorCodes.includes(ERROR_CODES.noFiles)) {
        setStatusBar({
          controller: context.statusBarController,
          type: STATUS_BAR_TYPES.error,
          text: 'fileNotFound',
          setVisible: true
        })
      }
    })
    .catch((error) => {
      console.error('Error in downloadReports:', error)
      setStatusBar({
        controller: context.statusBarController,
        type: STATUS_BAR_TYPES.error,
        text: 'reportCreationError',
        setVisible: true
      })
    })
}

export const downloadFileFromUrl = (url) => {
  const link = document.createElement('a')
  link.href = url
  document.body.appendChild(link)
  link.click()
  link.parentNode.removeChild(link)
}

export const scrollToElement = (container, elementId, width) => {
  const OFFSET_DEFAULT = -70
  const OFFSET_MOBILE = -130

  const yOffset = width < BREAKPOINT_MOBILE ? OFFSET_MOBILE : OFFSET_DEFAULT
  const element = container.querySelector(`#${elementId}`)
  if (!element) return
  const y = element.getBoundingClientRect().top + container.scrollTop + yOffset
  container.scrollTo({ top: y, behavior: 'smooth' })
}

export const getActivePage = (location, language) => {
  const pagesEntries = Object.entries(PAGES)
  return pagesEntries.find((entry) => {
    const slug = entry[1].urlSlugs[language] || entry[1].urlSlugs[DEFAULT_LANGUAGE]
    return slug === location.pathname
  })?.[1]
}

export const isDemo = (configVariant) => configVariant === 'Demo'
export const isCrossTest = (configVariant) => configVariant === 'Cross-Test'
export const isQuickScan = (configVariant) => configVariant === 'QuickScan'
export const isStandard = (configVariant) => configVariant === 'Standard'

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1)
export const lowerCaseFirstLetter = (string) => string.charAt(0).toLowerCase() + string.slice(1)

export const replaceLatexChars = (string) => string.replaceAll('$\\alpha$', 'α').replaceAll('~', ' ')

export const replaceSpanWithBold = (string) => {
  return reactStringReplace(string, /<span style="font-family: opensansb;">(.*?)<\/span>/g, (match, i) => (
    <span key={i} style={{ fontWeight: 600 }}>
      {match}
    </span>
  ))
}

export const copyToClipboard = (content, elementLabel, context) => {
  navigator.clipboard
    .writeText(content)
    .then(() => {
      setStatusBar({
        controller: context.statusBarController,
        type: STATUS_BAR_TYPES.success,
        text: ['copiedToClipboard', elementLabel],
        setVisible: true
      })
    })
    .catch(() => {
      setStatusBar({
        controller: context.statusBarController,
        type: STATUS_BAR_TYPES.error,
        text: ['copyToClipboardFailed', elementLabel],
        setVisible: true
      })
    })
}

export const CopyIconTooltip = () => (
  <ReactTooltip
    id="copy-icon"
    aria-haspopup="true"
    effect="solid"
    place="right"
    backgroundColor="var(--c-tooltip-bg)"
    textColor="var(--tooltip-text-color)"
    delayShow={1000}
  />
)

export const getKeyByValue = (object, value) => Object.keys(object).find((key) => object[key] === value)

const objectIsEmpty = (entity) => Object.keys(entity).length === 0

export const showMissingReferenceNotice = (entity, bookingKey) => {
  return showRelatedEntities(bookingKey) && objectIsEmpty(entity)
}

export const showRelatedEntities = (bookingKey) => {
  return bookingKey === BOOKING_KEYS.pt_finished || bookingKey === BOOKING_KEYS.pt_reset
}

export const getCustomFieldOptions = (optionString) => {
  if (!optionString || !typeof optionString === 'string' || optionString === '') {
    return []
  }

  const options = optionString.split(',')
  return options.map((option) => {
    const trimmedOption = option.trim()
    const obj = { label: trimmedOption, value: trimmedOption }
    return obj
  })
}

export const getCustomFieldContent = (participant, customFieldDefinition) => {
  const field = participant?.customFields?.find((cf) => cf.publicKey === customFieldDefinition.publicKey)
  if (!field) return ''

  if (customFieldDefinition.type === 'select') {
    const options = getCustomFieldOptions(customFieldDefinition.options)
    return getOptionByValue(options, field.content).label || ''
  }
  return field.content
}

export const arrayToCsvString = (array, delimiter = ';') => array.join(delimiter + ' ')

export const trapFocus = (focusableElements) => {
  const firstTabbable = focusableElements[0] || document
  const lastTabbable = focusableElements[focusableElements.length - 1] || document
  document.body.classList.add('focus-trapped')

  function redirectToLast(e) {
    if (e.key === 'Tab' && e.shiftKey && document.body.classList.contains('focus-trapped')) {
      e.preventDefault()
      lastTabbable.focus()
    }
  }

  function redirectToFirst(e) {
    if (e.key === 'Tab' && !e.shiftKey && document.body.classList.contains('focus-trapped')) {
      e.preventDefault()
      firstTabbable.focus()
    }
  }

  /* redirect first shift+tab to last input */
  firstTabbable.addEventListener('keydown', redirectToLast)

  /* redirect last tab to first input */
  lastTabbable.addEventListener('keydown', redirectToFirst)

  return {
    removeEventListeners: () => {
      firstTabbable.removeEventListener('keydown', redirectToLast)
      lastTabbable.removeEventListener('keydown', redirectToFirst)
    }
  }
}

export const focusH2 = () => {
  const h2 = document.querySelector('.header-content-container h2')
  if (h2) {
    h2.tabIndex = -1
    h2.focus()
  }
}

export const handleDatePickerKeyDown = (event, datePickerRef) => {
  if (datePickerRef?.current && !datePickerRef.current.state.open) {
    if (event.key === 'Enter') {
      event.preventDefault()
      datePickerRef.current?.setOpen(true)
    }
  }
}

export const isGermanZipCode = (zipCode) => GERMAN_ZIP_REGEX.test(zipCode)
export const isOnlyNumbers = (string) => NUMBERS_ONLY_REGEX.test(string)

export const getCityFromZipCode = async (zipCode) => {
  const url = `https://zip-api.eu/api/v1/info/DE-${zipCode}`

  const response = await fetch(url)
  const data = await response.json()
  try {
    return data.place_name
  } catch (e) {
    console.error(e)
  }
}

export const someParticipantsUsingTimer = (participants) => {
  return participants.some((p) => p.ptList.some((pt) => pt.ptInvitedDuration > 0))
}

export const allPtsInHub = (pts) => pts.every((pt) => pt.relatedAssessment.inHub)

export const getUniqueProcessesFromPtList = (ptList) => {
  const processes = ptList.map((pt) => pt.relatedProcess)
  return [...new Set(processes)]
}

export const isInteger = (value) => typeof value === 'number' && Number.isInteger(value)

export const getCreditBalance = (creditBookings) => {
  if (creditBookings) {
    const booking_values = creditBookings.map((booking) => booking.creditsValue)
    return booking_values.reduce((total, num) => total + num, 0)
  }
  return 0
}

export const getLanguageOptions = (availableLanguages, assessment) => {
  if (!availableLanguages || !Array.isArray(availableLanguages)) {
    return [
      {
        label: LANGUAGE_NAMES[assessment.languageIds[0]],
        value: assessment.languageIds[0]
      }
    ]
  }
  return availableLanguages.map((option) => ({
    label: LANGUAGE_NAMES[option.value],
    value: option.value
  }))
}

export const getTranslatedReferenceGroupInfos = (config, locale) => ({
  referenceGroupTitle:
    config.referenceGroup[locale]['referenceGroupTitle'] ||
    config.referenceGroup[DEFAULT_LANGUAGE]['referenceGroupTitle'],
  referenceGroupDescription:
    config.referenceGroup[locale]['referenceGroupDescription'] ||
    config.referenceGroup[DEFAULT_LANGUAGE]['referenceGroupDescription']
})

export const disableConsoleLog = () => {
  console.log = () => {}
  console.warn = () => {}
  console.error = () => {}
}

export const getTestLanguagesString = (availableLanguages, languageIds) => {
  const filteredLanguages = languageIds
    ? availableLanguages.filter((lang) => languageIds.includes(lang.value))
    : availableLanguages
  return filteredLanguages
    .sort((a, b) => languageSortingOrder.indexOf(a.value) - languageSortingOrder.indexOf(b.value))
    .map((lang) => lang.label)
    .join(', ')
}

export const loadArchivedData = async (context) => {
  setStatusBar({
    controller: context.statusBarController,
    type: STATUS_BAR_TYPES.loading,
    text: 'archiveLoading',
    setVisible: true
  })
  return fetchData({}, 'archived_data', context, 'dataLoadError', false)
}

export const sanitizePercentValue = (value) => (isNaN(value) ? '' : parseInt(Math.min(Math.max(value, 0), 100)))

export const disableMouseWheelOnNumberInput = () => {
  document.addEventListener('wheel', function (e) {
    if (document.activeElement.type === 'number') {
      document.activeElement.blur()
      e.stopPropagation()
      setTimeout(() => {
        e.target.focus()
      }, 0)
    }
  })
}

export const chunkArray = (array, chunkSize) => {
  const chunks = []
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, chunkSize + i))
  }
  return chunks
}

export const updateResults = async (resultNrs, processResultNrs, context) => {
  const CHUNK_SIZE = 10
  if (!resultNrs.length) {
    return
  }

  context.setUpdatesArePaused(true)
  let progress = 0
  setStatusBar({
    controller: context.statusBarController,
    type: STATUS_BAR_TYPES.loading,
    text: ['updatingResults', progress],
    setVisible: true
  })

  const chunks = chunkArray(resultNrs, CHUNK_SIZE)
  const allUpdatedResults = []

  try {
    let chunkCount = 0
    for (const chunk of chunks) {
      chunkCount++
      progress = chunkCount / chunks.length

      const payload = { resultNrs: chunk }
      const { response } = await fetchData(payload, 'update_results', context, null, false)
      setStatusBar({
        controller: context.statusBarController,
        type: STATUS_BAR_TYPES.loading,
        text: ['updatingResults', progress],
        setVisible: true
      })
      allUpdatedResults.push(...response)
    }

    const { response } = await fetchData(
      { processResultNrs: processResultNrs },
      'get_process_results',
      context,
      null,
      false
    )

    updateEntities({ results: allUpdatedResults, processResults: response }, context)
  } catch (e) {
    console.error(e)
  } finally {
    context.setUpdatesArePaused(false)
  }
}

export const shakeElement = (element) => {
  element.classList.add('shake')
  setTimeout(() => {
    element.classList.remove('shake')
  }, 200)
}

export const getTruncatedList = (arr, t, max = 2) => {
  const shownElements = arr.filter((_, i) => i < max).join(', ')
  const additionalElements = arr.length - max
  const rest = ` + ${additionalElements} ${t('more')}`
  return additionalElements > 0 ? shownElements + rest : shownElements
}

export const getLightnessFromHex = (hex) => {
  hex = hex.replace(/^#/, '')

  if (hex.length !== 6) {
    return 0
  }

  const r = parseInt(hex.substring(0, 2), 16) / 255
  const g = parseInt(hex.substring(2, 4), 16) / 255
  const b = parseInt(hex.substring(4, 6), 16) / 255

  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)

  const lightness = (max + min) / 2

  return lightness
}

export const isValidHex = (hex) => {
  const hexPattern = /^#([A-Fa-f0-9]{6})$/
  return hexPattern.test(hex)
}

export const stripEmptyTags = (input) => {
  if (typeof input !== 'string') {
    return ''
  }

  return input.replace(/<(\w+)[^>]*>\s*<\/\1>/g, '')
}

export const stripAllTags = (input) => {
  if (typeof input !== 'string') {
    return ''
  }

  return input.replace(/<\/?[^>]+(>|$)/g, '')
}

import { SmallExternalLink } from '../elements/FormElements'
import { lowerCaseFirstLetter } from '../helper/Helper'

const dict = {
  customerLogin: {
    de: 'Kunden-Login',
    en: 'Customer Login'
  },
  firstName: {
    de: 'Vorname',
    en: 'First Name'
  },
  lastName: {
    de: 'Nachname',
    en: 'Last Name'
  },
  emailAddress: {
    de: 'E-Mail-Adresse',
    en: 'Email Address'
  },
  email: {
    de: 'E-Mail',
    en: 'Email'
  },
  emails: {
    de: 'E-Mails',
    en: 'Emails'
  },
  password: {
    de: 'Passwort',
    en: 'Password'
  },
  menu: {
    de: 'Menü',
    en: 'Menu'
  },
  help: {
    de: 'Hilfe',
    en: 'Help'
  },
  invitedDuration: {
    de: 'Timer',
    en: 'Timer'
  },
  startedDuration: {
    de: 'Zeit ab Teststart',
    en: 'Time from Test Start'
  },
  isSupervised: {
    de: 'Beaufsichtigung vor Ort',
    en: 'On-Site Supervision'
  },
  useChildItems: {
    de: 'Zufällige Aufgabenwahl',
    en: 'Random Item Selection'
  },
  inHub: {
    de: 'Auf Testübersicht anzeigen',
    en: 'Show in Test Overview'
  },
  testLanguages: {
    de: 'Test-Sprachen',
    en: 'Test Languages'
  },
  testLanguage: {
    de: 'Test-Sprache',
    en: 'Test Language'
  },
  salutation: {
    de: 'Anrede',
    en: 'Salutation'
  },
  phoneNumber: {
    de: 'Telefon-Nr.',
    en: 'Phone Number'
  },
  phone: {
    de: 'Telefon',
    en: 'Phone'
  },
  elementName: {
    de: 'Name',
    en: 'Name'
  },
  comment: {
    de: 'Kommentar',
    en: 'Comment'
  },
  personalName: {
    de: 'Name',
    en: 'Name'
  },
  pNumber: {
    de: 'Teilnehmer-Nr.',
    en: 'Participant Number'
  },
  participantName: {
    de: 'Teilnehmer Name',
    en: 'Participant Name'
  },
  ptNumber: {
    de: 'TAN',
    en: 'TAN'
  },
  oldPassword: {
    de: 'Altes Passwort',
    en: 'Old Password'
  },
  newPassword: {
    de: 'Neues Passwort',
    en: 'New Password'
  },
  street: {
    de: 'Straße',
    en: 'Street'
  },
  addressAddition: {
    de: 'Abteilung / Fachbereich',
    en: 'Department'
  },
  zipCode: {
    de: 'PLZ',
    en: 'ZIP Code'
  },
  city: {
    de: 'Ort',
    en: 'City'
  },
  country: {
    de: 'Land',
    en: 'Country'
  },
  contactIsAdmin: {
    de: 'Administrator-Rechte',
    en: 'Admin Permissions'
  },
  gender: {
    de: 'Geschlecht',
    en: 'Gender'
  },
  durationMultiplier: {
    de: 'Zeitverlängerung',
    en: 'Duration Multiplier'
  },
  credits: {
    de: 'Credits',
    en: 'Credits'
  },
  maxCredits: {
    de: 'Gesamtwert in Credits',
    en: 'Total Value in Credits'
  },
  invitation: {
    de: 'Einladung',
    en: 'Invitation'
  },
  invitations: {
    de: 'Einladungen',
    en: 'Invitations'
  },
  reminder: {
    de: 'Erinnerung',
    en: 'Reminder'
  },
  invitationStatus: {
    de: 'Einladungsstatus',
    en: 'Invitation Status'
  },
  referenceAssessment: {
    de: 'Referenz-Test',
    en: 'Reference Test'
  },
  create: {
    de: 'Erstellen',
    en: 'Create'
  },
  liveData: {
    de: 'Live-Daten',
    en: 'Live Data'
  },
  enlargedView: {
    de: 'Vergrößerte Ansicht',
    en: 'Enlarge'
  },
  documents: {
    de: 'Dokumente',
    en: 'Documents'
  },
  dataSecurity: {
    de: 'Datenschutz',
    en: 'Privacy'
  },
  dataSecuritySettings: {
    de: 'Datenschutz',
    en: 'Privacy Settings'
  },
  imprint: {
    de: 'Impressum',
    en: 'Legal Notice'
  },
  logout: {
    de: 'Logout',
    en: 'Log out'
  },
  processes: {
    de: 'Verfahren',
    en: 'Processes'
  },
  process: {
    de: 'Verfahren',
    en: 'Process'
  },
  participants: {
    de: 'Teilnehmer',
    en: 'Participants'
  },
  participant: {
    de: 'Teilnehmer',
    en: 'Participant'
  },
  pts: {
    de: 'Testungen',
    en: 'Testings'
  },
  pt: {
    de: 'Testung',
    en: 'Testing'
  },
  user: {
    de: 'Nutzer',
    en: 'User'
  },
  users: {
    de: 'Nutzer',
    en: 'Users'
  },
  bookings: {
    de: 'Buchungen',
    en: 'Bookings'
  },
  booking: {
    de: 'Buchung',
    en: 'Booking'
  },
  overview: {
    de: 'Übersicht',
    en: 'Overview'
  },
  noActiveTests: {
    de: 'Aktuell werden keine Tests durchgeführt',
    en: 'No active tests at the moment'
  },
  inTheArchive: {
    de: 'im Archiv',
    en: 'archived'
  },
  archive: {
    de: 'Archiv',
    en: 'Archive'
  },
  deleteAccountNotice: {
    de: (customerName, contactMail) => (
      <>
        Hier können Sie das Unternehmenskonto <span style={{ fontWeight: 600 }}>{customerName}</span> löschen. Bitte
        beachten Sie, dass dabei alle Daten verloren gehen. Ihr Nutzerkonto{' '}
        <span style={{ fontWeight: 600 }}>{contactMail}</span> können Sie in der Nutzerverwaltung löschen.
      </>
    ),
    en: (customerName, contactMail) => (
      <>
        You can delete the company account <span style={{ fontWeight: 600 }}>{customerName}</span> here. Please note
        that all data will be lost in the process. You can delete your user account{' '}
        <span style={{ fontWeight: 600 }}>{contactMail}</span> in the user management.
      </>
    )
  },
  account: {
    de: 'Konto',
    en: 'Account'
  },
  companyData: {
    de: 'Unternehmensdaten',
    en: 'Company Data'
  },
  company: {
    de: 'Unternehmen',
    en: 'Company'
  },
  authority: {
    de: 'Behörde',
    en: 'Office'
  },
  businessEmail: {
    de: 'Geschäftliche E-Mail-Adresse',
    en: 'Business Email'
  },
  accountSettings: {
    de: 'Kontoeinstellungen',
    en: 'Account Settings'
  },
  settings: {
    de: 'Einstellungen',
    en: 'Settings'
  },
  settingsSaved: {
    de: 'Einstellungen gespeichert',
    en: 'Settings saved'
  },
  save: {
    de: 'Speichern',
    en: 'Save'
  },
  cancel: {
    de: 'Abbrechen',
    en: 'Cancel'
  },
  close: {
    de: 'Schließen',
    en: 'Close'
  },
  newResults: {
    de: 'Neueste Testergebnisse',
    en: 'Latest Results'
  },
  balance: {
    de: 'Guthaben',
    en: 'Credit Balance'
  },
  testsRun: {
    de: 'Durchgeführte Tests',
    en: 'Tests Completed'
  },
  today: {
    de: 'heute',
    en: 'today'
  },
  thisWeek: {
    de: 'diese Woche',
    en: 'this week'
  },
  thisMonth: {
    de: 'diesen Monat',
    en: 'this month'
  },
  thisYear: {
    de: 'dieses Jahr',
    en: 'this year'
  },
  overall: {
    de: 'Insgesamt',
    en: 'Overall'
  },
  validFrom: {
    de: 'Verfügbar ab',
    en: 'Start Time'
  },
  validUntil: {
    de: 'Verfügbar bis',
    en: 'End Time'
  },
  deactivate: {
    de: 'Deaktivieren',
    en: 'Deactivate'
  },
  deactivateTesting: {
    de: 'Testung deaktivieren',
    en: 'Deactivate Testing'
  },
  activate: {
    de: 'Aktivieren',
    en: 'Activate'
  },
  activateTesting: {
    de: 'Testung aktivieren',
    en: 'Activate Testing'
  },
  delete: {
    de: 'Löschen',
    en: 'Delete'
  },
  buy: {
    de: 'Kaufen',
    en: 'Buy'
  },
  role: {
    de: 'Rolle',
    en: 'Role'
  },
  sendMail: {
    de: 'E-Mail senden',
    en: 'Send email'
  },
  exportCsv: {
    de: 'Als CSV exportieren',
    en: 'Export as CSV'
  },
  keep: {
    de: 'Behalten',
    en: 'Keep'
  },
  change: {
    de: 'Ändern',
    en: 'Change'
  },
  changeDotDot: {
    de: 'Ändern…',
    en: 'Change…'
  },
  apply: {
    de: 'Übernehmen',
    en: 'Apply'
  },
  no: {
    de: 'nein',
    en: 'no'
  },
  yes: {
    de: 'ja',
    en: 'yes'
  },
  immediately: {
    de: 'sofort',
    en: 'immediately'
  },
  setStartTime: {
    de: 'Startzeitpunkt festlegen...',
    en: 'set start time...'
  },
  unlimitedValidUntil: {
    de: 'unbegrenzt',
    en: 'unlimited'
  },
  setEndTime: {
    de: 'Endzeitpunkt festlegen...',
    en: 'set end time...'
  },
  noTimer: {
    de: 'kein Timer',
    en: 'no timer'
  },
  setTimer: {
    de: 'Timer einstellen...',
    en: 'set timer...'
  },
  unlimitedStartedDuration: {
    de: 'unbegrenzt',
    en: 'unlimited'
  },
  limitTime: {
    de: 'Zeit begrenzen...',
    en: 'limit time...'
  },
  mrSalutation: {
    de: 'Herr',
    en: 'Mr.'
  },
  mrsSalutation: {
    de: 'Frau',
    en: 'Mrs.'
  },
  namedExecution: {
    de: 'Durchführung mit Namen',
    en: 'Named Testing'
  },
  anonymousExecution: {
    de: 'Anonyme Durchführung',
    en: 'Anonymous Testing'
  },
  noAuthorization: {
    de: 'Keine Freigabe',
    en: 'No authorization'
  },
  viewerAuthorization: {
    de: 'Betrachter',
    en: 'Viewer'
  },
  editorAuthorization: {
    de: 'Editor',
    en: 'Editor'
  },
  withoutSupervision: {
    de: 'ohne Aufsicht',
    en: 'without supervision'
  },
  withSupervision: {
    de: 'mit Aufsicht',
    en: 'with supervision'
  },
  notActivated: {
    de: 'nicht aktiviert',
    en: 'not activated'
  },
  recommendedActivated: {
    de: 'aktiviert (empfohlen)',
    en: 'activated (recommended)'
  },
  yesRecommended: {
    de: 'ja (empfohlen)',
    en: 'yes (recommended)'
  },
  doNotShow: {
    de: 'nicht anzeigen',
    en: 'do not show'
  },
  show: {
    de: 'anzeigen',
    en: 'show'
  },
  maleGender: {
    de: 'männlich',
    en: 'male'
  },
  femaleGender: {
    de: 'weiblich',
    en: 'female'
  },
  diverseGender: {
    de: 'divers',
    en: 'diverse'
  },
  notSpecified: {
    de: 'keine Angabe',
    en: 'not specified'
  },
  none: {
    de: 'keine',
    en: 'none'
  },
  blankParticipant: {
    de: 'Blanko Teilnehmer',
    en: 'Blank participants'
  },
  participantList: {
    de: 'Teilnehmerliste',
    en: 'Participant list'
  },
  invitationEmail: {
    de: 'Einladung',
    en: 'Invitation'
  },
  reminderEmail: {
    de: 'Erinnerung',
    en: 'Reminder'
  },
  existingProcess: {
    de: 'Bestehendes Verfahren',
    en: 'Existing process'
  },
  newProcess: {
    de: 'Neues Verfahren',
    en: 'New Process'
  },
  doNotUseProcessResult: {
    de: 'Tests nicht verrechnen',
    en: 'Do not combine test scores'
  },
  useProcessResult: {
    de: 'Tests verrechnen',
    en: 'Combine test scores'
  },
  oneMonth: {
    de: '1 Monat',
    en: '1 month'
  },
  threeMonths: {
    de: '3 Monate',
    en: '3 months'
  },
  sixMonths: {
    de: '6 Monate',
    en: '6 months'
  },
  twelveMonths: {
    de: '12 Monate',
    en: '12 months'
  },
  twentyFourMonths: {
    de: '24 Monate',
    en: '24 months'
  },
  never: {
    de: 'nie',
    en: 'never'
  },
  customFieldSelection: {
    de: 'Auswahl',
    en: 'Select'
  },
  notInvited: {
    de: 'nicht eingeladen',
    en: 'not invited'
  },
  invited: {
    de: 'eingeladen',
    en: 'invited'
  },
  reminded: {
    de: 'erinnert',
    en: 'reminded'
  },
  testAndProcess: {
    de: 'Test und Verfahren',
    en: 'Test and Process'
  },
  added: {
    de: 'Hinzugefügt',
    en: 'Added'
  },
  ready: {
    de: 'Bereit',
    en: 'Ready'
  },
  startedAt: {
    de: 'Gestartet am',
    en: 'Date Started'
  },
  started: {
    de: 'Gestartet',
    en: 'Started'
  },
  notStarted: {
    de: 'nicht gestartet',
    en: 'not started'
  },
  finished: {
    de: 'Abgeschlossen',
    en: 'Finished'
  },
  waiting: {
    de: 'Wartend',
    en: 'Waiting'
  },
  deactivated: {
    de: 'Deaktiviert',
    en: 'Deactivated'
  },
  excluded: {
    de: 'Ausgeschlossen',
    en: 'Excluded'
  },
  expired: {
    de: 'Abgelaufen',
    en: 'Expired'
  },
  totalParticipants: {
    de: 'Teilnehmer insgesamt',
    en: 'Total Participants'
  },
  averagePoints: {
    de: 'Durchschnittliche Punkte',
    en: 'Average Points'
  },
  averageGrade: {
    de: 'Durchschnittliche Note',
    en: 'Average Grade'
  },
  result: {
    de: 'Ergebnis',
    en: 'Result'
  },
  results: {
    de: 'Ergebnisse',
    en: 'Results'
  },
  resultPoints: {
    de: 'Ergebnis (Punkte)',
    en: 'Result (Points)'
  },
  resultGrade: {
    de: 'Ergebnis (Note)',
    en: 'Result (Grade)'
  },
  resultCrosstest: {
    de: 'Ergebnis (Crosstest)',
    en: 'Result (Cross Test)'
  },
  relatedTests: {
    de: 'Zugeordnete Tests',
    en: 'Related Tests'
  },
  relatedTest: {
    de: 'Zugeordneter Test',
    en: 'Related Test'
  },
  dateCreated: {
    de: 'Erstellungsdatum',
    en: 'Date Created'
  },
  createdBy: {
    de: 'Erstellt von',
    en: 'Created by'
  },
  actions: {
    de: 'Aktionen',
    en: 'Actions'
  },
  capabilities: {
    de: 'Zugriffsrechte',
    en: 'Permissions'
  },
  changeCapabilities: {
    de: 'Rechte ändern',
    en: 'Change Permissions'
  },
  putInArchive: {
    de: 'Archivieren',
    en: 'Archive'
  },
  downloadCsv: {
    de: 'CSV-Tabelle downloaden',
    en: 'Download CSV Table'
  },
  downloadReports: {
    de: 'Reports downloaden',
    en: 'Download Reports'
  },
  duplicateProcess: {
    de: 'Verfahren duplizieren',
    en: 'Duplicate Process'
  },
  addParticipants: {
    de: 'Teilnehmer hinzufügen',
    en: 'Add Participants'
  },
  addTest: {
    de: 'Test hinzufügen',
    en: 'Add Test'
  },
  baseSettings: {
    de: 'Basis-Einstellungen',
    en: 'Base Settings'
  },
  processResultHeadline: {
    de: 'Verrechnung zu Gesamtergebnis',
    en: 'Combination of Test Scores'
  },
  weight: {
    de: 'Gewicht',
    en: 'Weight'
  },
  weighting: {
    de: 'Gewichtung',
    en: 'Weighting'
  },
  sum: {
    de: 'Summe',
    en: 'Total'
  },
  empty: {
    de: 'leer',
    en: 'empty'
  },
  duration: {
    de: 'Dauer',
    en: 'Duration'
  },
  statistics: {
    de: 'Statistiken',
    en: 'Statistics'
  },
  infos: {
    de: 'Test-Infos',
    en: 'Infos'
  },
  baseInfos: {
    de: 'Basis-Informationen',
    en: 'Base Infos'
  },
  notSet: {
    de: 'nicht gesetzt',
    en: 'not set'
  },
  testType: {
    de: 'Test-Typ',
    en: 'Test Type'
  },
  testName: {
    de: 'Testname',
    en: 'Test Name'
  },
  assessmentName: {
    de: 'Testname',
    en: 'Test Name'
  },
  referenceGroup: {
    de: 'Referenzgruppe',
    en: 'Reference Group'
  },
  lastUpdate: {
    de: 'Stand',
    en: 'Last Update'
  },
  reportModules: {
    de: 'Reportmodule',
    en: 'Report Modules'
  },
  testContent: {
    de: 'Testinhalte',
    en: 'Test Content'
  },
  points: {
    de: 'Punkte',
    en: 'Points'
  },
  grade: {
    de: 'Note',
    en: 'Grade'
  },
  participation: {
    de: 'Teilnahme',
    en: 'Participation'
  },
  column: {
    de: 'Spalte',
    en: 'Column'
  },
  visible: {
    de: 'Sichtbar',
    en: 'Visible'
  },
  ptsAndResults: {
    de: 'Testungen und Ergebnisse',
    en: 'Testings and Results'
  },
  ptsAndStatus: {
    de: 'Testungen und Status',
    en: 'Testings and Status'
  },
  totalScore: {
    de: 'Gesamtergebnis',
    en: 'Total Score'
  },
  linkToTestOverview: {
    de: 'Link zur Testübersicht',
    en: 'Link to Test Overview'
  },
  summary: {
    de: 'Zusammenfassung',
    en: 'Summary'
  },
  individualResults: {
    de: 'Einzelergebnisse',
    en: 'Individual Results'
  },
  csvRawdata: {
    de: 'CSV Rohdaten',
    en: 'CSV Raw Data'
  },
  accessData: {
    de: 'Zugangsdaten',
    en: 'Access Data'
  },
  testOverview: {
    de: 'Testübersicht',
    en: 'Test Overview'
  },
  invitationLink: {
    de: 'Einladungslink',
    en: 'Invitation Link'
  },
  loginCode: {
    de: 'Login-Code',
    en: 'Login Code'
  },
  addParticipantsToTests: {
    de: 'Weitere Tests zuweisen',
    en: 'Assign additional Tests'
  },
  openReport: {
    de: 'Report öffnen',
    en: 'Open report'
  },
  personalData: {
    de: 'Persönliche Daten',
    en: 'Personal Data'
  },
  sendLinkOrLoginCode: {
    de: 'Schicken Sie Ihren Teilnehmern entweder den Einladungslink oder den Login-Code für ',
    en: 'Send the invitation link or the login code for '
  },
  toStartThisTest: {
    de: ', um diesen Test zu starten.',
    en: ' to your participants to start this test.'
  },
  toStartTheTests: {
    de: ', um die Tests zu starten',
    en: ' to your participants to start the tests.'
  },
  allTestsInHubFinishedNotice: {
    de: 'Alle Testungen auf der Testübersicht wurden bereits abgeschlossen.',
    en: 'All tests on the test overview have already been completed.'
  },
  allTestsInHubExpiredNotice: {
    de: 'Alle Testungen auf der Testübersicht sind bereits abgelaufen.',
    en: 'All tests on the test overview have already expired.'
  },
  allTestsInHubDeactivatedNotice: {
    de: 'Alle Testungen auf der Testübersicht sind deaktiviert.',
    en: 'All tests on the test overview are deactivated.'
  },
  archivedTestNotice: {
    de: 'Diese Testung liegt im Archiv und kann nicht durchgeführt werden.',
    en: 'This test is in the archive and cannot be conducted.'
  },
  finishedTestNotice: {
    de: 'Diese Testung wurde bereits abgeschlossen.',
    en: 'This test has already been completed.'
  },
  expiredTestNotice: {
    de: 'Der Bearbeitungszeitraum für diese Testung ist abgelaufen.',
    en: 'The testing period for this test has expired.'
  },
  deactivatedTestNotice: {
    de: 'Diese Testung ist deaktiviert und kann nicht durchgeführt werden.',
    en: 'This test is deactivated and cannot be conducted.'
  },
  bulkSidebarHeadline: {
    de: (selectRowCount, entityLabel) => (
      <>
        {selectRowCount} {entityLabel} <span className="hide-on-mobile">bearbeiten</span>
      </>
    ),
    en: (selectRowCount, entityLabel) => (
      <>
        <span className="hide-on-mobile">Edit</span> {selectRowCount} {entityLabel}
      </>
    )
  },
  windowChanges: {
    de: 'Fensterwechsel',
    en: 'Window Switches'
  },
  windowChangeCount: {
    de: 'Fensterwechsel (Anzahl)',
    en: 'Window Switch Count'
  },
  unpermittedAids: {
    de: 'Unerlaubte Hilfsmittel',
    en: 'Unpermitted Aids'
  },
  cameraAnomalies: {
    de: 'Kamera Auffälligkeiten',
    en: 'Camera Anomalies'
  },
  cameraAnomalieExplanation: {
    de: 'Die Kamera war verdeckt oder nicht auf den Teilnehmer gerichtet.',
    en: 'The camera was partly covered or not aimed at the participant.'
  },
  testLink: {
    de: 'Link zum Test',
    en: 'Link to the Test'
  },
  dateCompleted: {
    de: 'Abgeschlossen am',
    en: 'Date Finished'
  },
  timesAndSettings: {
    de: 'Zeiten und Einstellungen',
    en: 'Times and Settings'
  },
  bookingType: {
    de: 'Buchungstyp',
    en: 'Booking Type'
  },
  value: {
    de: 'Wert',
    en: 'Value'
  },
  doneTest: {
    de: 'Durchgeführter Test',
    en: 'Test'
  },
  bookingNumber: {
    de: 'Buchungsnummer',
    en: 'Booking Number'
  },
  adjustTableColumns: {
    de: 'Tabellenspalten anpassen',
    en: 'Adjust Table Columns'
  },
  showHideArchive: {
    de: 'Archivierte Elemente ein-/ausblenden',
    en: 'Toggle Archived Elements'
  },
  chargeCredits: {
    de: 'Credits aufladen',
    en: 'Charge Credits'
  },
  supportNotice: {
    de: 'Bei Fragen und Problemen sind wir gerne für Sie da. Schreiben Sie uns einfach eine E-Mail oder rufen uns an.',
    en: 'We are here to help with any questions or issues you may have. Simply send us an email or give us a call.'
  },
  next: {
    de: 'Weiter',
    en: 'Next'
  },
  back: {
    de: 'Zurück',
    en: 'Back'
  },
  unarchive: {
    de: 'Dearchivieren',
    en: 'Unarchive'
  },
  userAdministration: {
    de: 'Nutzerverwaltung',
    en: 'User Administration'
  },
  customFields: {
    de: 'Eigene Felder',
    en: 'Custom Fields'
  },
  changePassword: {
    de: 'Passwort ändern',
    en: 'Change Password'
  },
  notifications: {
    de: 'Benachrichtigungen',
    en: 'Notifications'
  },
  forgotPassword: {
    de: 'Passwort vergessen',
    en: 'Forgot Password'
  },
  getAccess: {
    de: 'Zugang anfordern',
    en: 'Get Access'
  },
  backToLogin: {
    de: 'Zurück zum Login',
    en: 'Back to Login'
  },
  login: {
    de: 'Einloggen',
    en: 'Log in'
  },
  elektryonRedirectText: {
    de: `Sie sind Bewerber/in? Hier geht's zum Test.`,
    en: `Are you an applicant? Click here to take the test.`
  },
  signUpText: {
    de: 'Als Unternehmen oder Behörde erhalten Sie kostenlosen Zugriff auf den PERSEO Kundenbereich mit allen Tests und Features.',
    en: 'As a company or agency, get free access to the PERSEO customer area with all tests and features.'
  },
  websiteLink: {
    de: 'Zur PERSEO Website',
    en: 'PERSEO Website'
  },
  resetPasswordInstructions: {
    de: () => (
      <>
        Bitte geben Sie Ihre E-Mail-Adresse ein und klicken auf{' '}
        <span style={{ whiteSpace: 'nowrap' }}>„E-Mail senden“</span>. Sie erhalten dann eine E-Mail mit weiteren
        Anweisungen zum Zurücksetzen Ihres Passworts.
      </>
    ),
    en: 'Please enter your email address and click “Send email”. You will then receive an email with further instructions to reset your password.'
  },
  loadingData: {
    de: 'lade Daten...',
    en: 'loading data...'
  },
  processingData: {
    de: 'verarbeite Daten...',
    en: 'processing data...'
  },
  customFieldsExplanation: {
    de: 'Hier können Sie eigene Felder für Ihre Teilnehmer anlegen. In der Teilnehmerübersicht können Sie Daten in Ihre Felder eintragen und danach filtern und sortieren.',
    en: 'Here you can create custom fields for your participants. In the participant overview, you can enter data into your fields and then filter and sort based on them.'
  },
  newField: {
    de: 'Neues Feld',
    en: 'New field'
  },
  type: {
    de: 'Typ',
    en: 'Type'
  },
  fieldKey: {
    de: 'Feld-Schlüssel',
    en: 'Field Key'
  },
  maxChars: {
    de: (limit) => `Maximal ${limit} Zeichen`,
    en: (limit) => `${limit} chars max`
  },
  maxCharsPolite: {
    de: (limit) => `Bitte maximal ${limit} Zeichen`,
    en: (limit) => `${limit} chars max please`
  },
  minChars: {
    de: (limit) => `Mindestens ${limit} Zeichen`,
    en: (limit) => `Must be at least ${limit} characters`
  },
  createdAutomatically: {
    de: 'Wird automatisch erzeugt',
    en: 'Created automatically'
  },
  options: {
    de: 'Optionen',
    en: 'Options'
  },
  seperateWithComma: {
    de: 'Optionen mit Komma trennen',
    en: 'Separate options with a comma'
  },
  automaticAnonymization: {
    de: 'Automatische Anonymisierung',
    en: 'Automatic Anonymization'
  },
  anonymizationExplanation: {
    de: 'Um Ihre Bewerberdaten nicht länger als nötig zu speichern, können wir diese nach Ablauf einer Frist automatisch anonymisieren. Namen und E-Mail-Adressen werden gelöscht, die Teilnehmernummern und Ergebnisse sind aber weiterhin verfügbar.',
    en: 'To avoid storing your applicant data longer than necessary, we can automatically anonymize it after a certain period. Names and email addresses will be deleted, but participant numbers and results will remain accessible.'
  },
  anonymizationDeadline: {
    de: 'Frist zur Anonymisierung',
    en: 'Anonymization Deadline'
  },
  deleteUser: {
    de: 'Nutzer löschen',
    en: 'Delete user'
  },
  deleteMyAccount: {
    de: 'Meinen Account löschen',
    en: 'Delete my account'
  },
  editCapabilitiesExplanation: {
    de: (contactLabel) =>
      `Legen Sie hier fest, welche Verfahren von ${contactLabel} eingesehen und editiert werden können. Administratoren
        können alle Verfahren einsehen und editieren.`,
    en: (contactLabel) =>
      `Specify here which processes can be viewed and edited by ${contactLabel}. Administrators can view and edit all
        processes.`
  },
  read: {
    de: 'gelesen',
    en: 'seen'
  },
  unread: {
    de: 'ungelesen',
    en: 'unread'
  },
  open: {
    de: 'öffnen',
    en: 'open'
  },
  date: {
    de: 'Datum',
    en: 'Date'
  },
  title: {
    de: 'Titel',
    en: 'Title'
  },
  startupNotifications: {
    de: 'Start-Nachrichten',
    en: 'Start Messages'
  },
  startupNotificationsExplanation: {
    de: 'Hier finden Sie alle bisherigen Start-Nachrichten und können diese nochmal lesen.',
    en: 'Here you can find all previous start messages and read them again.'
  },
  newsletterExplanation: {
    de: 'Hier können Sie sich für unseren Newsletter ein- und austragen. Im Newsletter informieren wir ca. zwei mal pro Jahr über Neuigkeiten und Änderungen unserer Tests und Software.',
    en: 'Here you can subscribe and unsubscribe to our newsletter. In the newsletter, we inform about news and changes to our tests and software approximately twice a year.'
  },
  subscribeToNewsletter: {
    de: 'Newsletter abonnieren',
    en: 'Subscribe to Newsletter'
  },
  completedTests: {
    de: 'Abgeschlossene Tests',
    en: 'Completed Tests'
  },
  emailNotificationSettings: {
    de: (email) => (
      <>
        Hier können Sie einstellen, wie oft Sie per E-Mail über neu abgeschlossene Tests informiert werden möchten. Die
        Benachrichtigung umfasst alle für Sie sichtbaren Tests und wird an{' '}
        <span style={{ fontWeight: 600 }}>{email}</span> gesendet.
      </>
    ),
    en: (email) => (
      <>
        Here you can set how often you want to be notified by email about newly completed tests. The notification
        includes all tests visible to you and will be sent to <span style={{ fontWeight: 600 }}>{email}</span>.
      </>
    )
  },
  dailyFrequency: {
    de: '1 x pro Tag',
    en: '1 x per day'
  },
  weeklyFrequency: {
    de: '1 x pro Woche',
    en: '1 x per week'
  },
  monthlyFrequency: {
    de: '1 x pro Monat',
    en: '1 x per month'
  },
  contactForQuestions: {
    de: 'Für Rückfragen',
    en: 'For inquiries'
  },
  emailAsLogin: {
    de: 'Diese E-Mail-Adresse ist auch Ihr Login für den Kundenbereich.',
    en: 'This email address is also your login for the customer area.'
  },
  contactPerson: {
    de: 'Ansprechpartner',
    en: 'Contact Person'
  },
  additionalFields: {
    de: 'Zusatzfelder',
    en: 'Additional Fields'
  },
  content: {
    de: 'Inhalt',
    en: 'Content'
  },
  field: {
    de: 'Feld',
    en: 'Field'
  },
  newValue: {
    de: 'Neuer Wert',
    en: 'New Value'
  },
  additionalInvoiceFields: {
    de: 'Hier können Sie zusätzliche Felder für Ihre Rechnung vergeben, wie z.B. Lieferanten-Nr. oder Kostenstelle.',
    en: 'Here you can assign additional fields for your invoice, such as supplier number or cost center.'
  },
  address: {
    de: 'Anschrift',
    en: 'Address'
  },
  emailConfirmationAndInvoice: {
    de: 'An diese E-Mail-Adresse senden wir die Auftragsbestätigung und die Rechnung.',
    en: 'We will send the order confirmation and the invoice to this email address.'
  },
  deleteCompanyAccount: {
    de: 'Unternehmenskonto löschen',
    en: 'Delete Company Account'
  },
  confirmDeleteCompanyAccount: {
    de: (customerName) => (
      <>
        Sind Sie sicher, dass Sie das Unternehmenskonto <span style={{ fontWeight: 600 }}>{customerName}</span>{' '}
        vollständig löschen möchten? Hierdurch verlieren Sie den Zugriff auf unsere validierten Leistungs- und
        Persönlichkeitstests und löschen...
      </>
    ),
    en: (customerName) => (
      <>
        Are you sure you want to completely delete the company account{' '}
        <span style={{ fontWeight: 600 }}>{customerName}</span>? You will loose access to our validated performance and
        personality tests, and...
      </>
    )
  },
  deleteNorm: {
    de: 'Benotungsregel löschen?',
    en: 'Delete grading rule?'
  },
  deleteScreen: {
    de: 'Seite löschen?',
    en: 'Delete Screen?'
  },
  confirmNormDelete: {
    de: 'Sind Sie sicher, dass Sie die gewählte Benotungsregel löschen möchten?',
    en: 'Are you sure you want to delete the selected grading rule?'
  },
  confirmScreenDelete: {
    de: 'Sind Sie sicher, dass Sie die gewählte Seite löschen möchten?',
    en: 'Are you sure you want to delete the selected screen?'
  },
  confirmTemplateDelete: {
    de: 'Sind Sie sicher, dass Sie die gewählte E-Mail-Vorlage löschen möchten?',
    en: 'Are you sure you want to delete the selected email template?'
  },
  overwriteNorm: {
    de: 'Benotungsregel überschreiben?',
    en: 'Overwrite grading rule?'
  },
  overwriteScreen: {
    de: 'Seite überschreiben?',
    en: 'Overwrite screen?'
  },
  overwriteTemplate: {
    de: 'Vorlage überschreiben?',
    en: 'Overwrite template?'
  },
  finalDeletionInstructions: {
    de: 'Wenn Sie das Konto endgültig löschen möchten, tragen Sie bitte hier Ihre E-Mail-Adresse ein und klicken anschließend auf „Löschen“.',
    en: 'If you want to permanently delete the account, please enter your email address here and then click “Delete.”'
  },
  basicInformation: {
    de: 'Basis-Angaben',
    en: 'Basic Information'
  },
  additionalInformation: {
    de: 'Weitere Angaben',
    en: 'Additional Information'
  },
  invitationAddress: {
    de: 'An diese Adresse senden wir die Einladung.',
    en: 'We will send the invitation to this address.'
  },
  adminAccessExplanation: {
    de: 'Administratoren haben Zugriff auf alle Verfahren und können Nutzer anlegen und löschen.',
    en: 'Administrators have access to all processes and can create and delete users.'
  },
  userFieldCompletion: {
    de: 'Der Nutzer kann diese Felder später auch selbst ausfüllen.',
    en: 'The user can also fill in these fields themselves later.'
  },
  createUser: {
    de: 'Nutzer anlegen',
    en: 'Add User'
  },
  userData: {
    de: 'Nutzerdaten',
    en: 'User Data'
  },
  checkInputs: {
    de: 'Eingaben prüfen',
    en: 'Check Inputs'
  },
  permissions: {
    de: 'Berechtigungen',
    en: 'Permissions'
  },
  preview: {
    de: 'Vorschau',
    en: 'Preview'
  },
  setPermissions: {
    de: 'Berechtigungen festlegen',
    en: 'Set Permissions'
  },
  invite: {
    de: 'Einladen',
    en: 'Invite'
  },
  testLinkInstructions: {
    de: 'Zum Testlink in 3 Schritten',
    en: 'To the Test Link in 3 Steps'
  },
  createProcess: {
    de: 'Verfahren anlegen',
    en: 'Create Process'
  },
  createTest: {
    de: 'Test anlegen',
    en: 'Create Test'
  },
  createParticipants: {
    de: 'Teilnehmer anlegen',
    en: 'Create Participants'
  },
  newTest: {
    de: 'Neuer Test',
    en: 'New Test'
  },
  newParticipants: {
    de: 'Neue Teilnehmer',
    en: 'New Participants'
  },
  processExplanation: {
    de: 'Ein Verfahren ist ein Ordner für zusammengehörige Tests.',
    en: 'A process serves as a folder for related tests.'
  },
  testExplanation: {
    de: 'Ein Test ist eine Zusammenstellung aus verschiedenen Testmodulen.',
    en: 'A test is a compilation of various test modules.'
  },
  testLinkCreationExplanation: {
    de: 'Um einen Testlink zu erstellen, braucht Ihr Test einen Teilnehmer.',
    en: 'To create a test link, your test needs a participant.'
  },
  addProcessHint1: {
    de: 'Sie können z.B. ein Verfahren je Stellenausschreibung oder Berufsgruppe erstellen.',
    en: 'For example, you can create a process for each job posting or professional group.'
  },
  addProcessHint2: {
    de: 'Ein Verfahren kann mehrere Tests und Testarten enthalten.',
    en: 'A process can contain multiple tests and types of tests.'
  },
  addProcessHint3: {
    de: 'Verfahren können nur von Administratoren erstellt werden.',
    en: 'Processes can only be created by administrators.'
  },
  addTestHint1: {
    de: 'Jeder Test ist darauf ausgelegt, spezifische Eigenschaften oder Fähigkeiten einer bestimmten Teilnehmergruppe zu messen.',
    en: 'Each test is designed to measure specific attributes or abilities of a particular group of participants.'
  },
  addTestHint2: {
    de: 'Ein Test muss einem Verfahren zugeordnet sein.',
    en: 'A test must be assigned to a process.'
  },
  addParticipantHint1: {
    de: 'Jeder Teilnehmer kann einen oder mehrere Tests durchführen. Die Teilnahme an einem Test nennen wir „Testung“.',
    en: 'Each participant can take one or more tests. The participation in a test is referred to as “testing”.'
  },
  addParticipantHint2: {
    de: 'Die Testung wird automatisch angelegt, wenn Sie einen Teilnehmer hinzufügen.',
    en: 'The testing will be automatically created when you add a participant.'
  },
  nameAndDesign: {
    de: 'Name und Design',
    en: 'Name and Design'
  },
  newProcessName: {
    de: 'Name des neuen Verfahrens',
    en: 'Name of the new process'
  },
  customDesign: {
    de: 'Individuelles Design',
    en: 'Custom Design'
  },
  notAvailable: {
    de: 'nicht verfügbar',
    en: 'not available'
  },
  processExplanationWithExample: {
    de: 'Ein Verfahren ist ein Ordner für zusammengehörige Tests. Sie können z.B. ein Verfahren je Stellenausschreibung oder Berufsgruppe erstellen.',
    en: 'A process is a folder for related tests. For example, you can create a process for each job posting or professional group.'
  },
  customDesignOption: {
    de: 'Unsere Tests sind standardmäßig im PERSEO Design gehalten. Wenn Sie Ihr eigenes Logo, Schriftart und Ihre Hausfarben wünschen, erstellen wir Ihnen gern einmalig ein individuelles Design, das Sie für alle Ihre Tests verwenden können.',
    en: 'Our tests are typically designed in the PERSEO style. If you wish to have your own logo, font, and brand colors, we are happy to create a unique design for you that you can use for all your tests.'
  },
  chooseTestDesign: {
    de: 'Welches Test-Design möchten Sie nutzen?',
    en: 'Which test design would you like to use?'
  },
  chooseTest: {
    de: 'Test wählen',
    en: 'Choose Test'
  },
  findPreconfiguredTest: {
    de: 'Finden Sie hier Ihren vorkonfigurierten PERSEO Test.',
    en: 'Find your preconfigured PERSEO test here.'
  },
  jobProfiles: {
    de: 'Berufsbilder',
    en: 'Job Profiles'
  },
  careerLevels: {
    de: 'Karrierestufen',
    en: 'Career Levels'
  },
  chooseVariant: {
    de: 'Variante wählen',
    en: 'Choose Variant'
  },
  descriptionStandard: {
    de: 'Detaillierter und umfangreicher berufsbezogener Eignungstest',
    en: 'Detailed and comprehensive job-specific aptitude test'
  },
  descriptionQuickScan: {
    de: 'Ökonomischer Eignungstest für den schnellen Überblick',
    en: 'Economical aptitude test for a quick overview'
  },
  descriptionCrosstest: {
    de: 'Kurzer Test zur Überprüfung von unbeaufsichtigten Onlinetests',
    en: 'Short test to verify unsupervised online tests'
  },
  descriptionDemo: {
    de: 'Gratis Demotest mit allen Fragetypen und Demoreports',
    en: 'Free demo test with all question types and demo reports'
  },
  variant: {
    de: 'Variante',
    en: 'Variant'
  },
  testNameAndProcess: {
    de: 'Testname und Verfahren',
    en: 'Test Name and Process'
  },
  newTestName: {
    de: 'Name des neuen Tests',
    en: 'Name of the new test'
  },
  nameVisibleToCandidates: {
    de: 'Der Name ist für die Kandidaten sichtbar.',
    en: 'The name is visible to the candidates.'
  },
  assignmentToProcess: {
    de: 'Zuordnung zu einem Verfahren',
    en: 'Assignment to a process'
  },
  testAssignmentExplanation: {
    de: 'Jeder Test muss einem Verfahren zugeordnet sein. Sie können den Test einem bestehenden Verfahren zuordnen oder ein neues Verfahren anlegen.',
    en: 'Each test must be assigned to a process. You can assign the test to an existing process or create a new process.'
  },
  testAssignmentExplanationShort: {
    de: 'Bitte fügen Sie den neuen Test einem Verfahren hinzu.',
    en: 'Please assign the new test to a process.'
  },
  selectProcess: {
    de: 'Bitte wählen Sie ein Verfahren aus',
    en: 'Please select a process'
  },
  new: {
    de: 'neu',
    en: 'new'
  },
  testDurationAndTiming: {
    de: 'Testzeitraum und -dauer',
    en: 'Test Duration and Timing'
  },
  timePeriod: {
    de: 'Zeitraum',
    en: 'Time Period'
  },
  settingsAdjustableLater: {
    de: 'Diese Einstellungen können Sie auch später anpassen.',
    en: 'You can also adjust these settings later.'
  },
  validFromExplanation: {
    de: 'Wenn Sie einen Startzeitpunkt festlegen, kann der Test erst ab diesem Zeitpunkt gestartet werden.',
    en: 'If you set a start time, the test can only be started from that point onwards.'
  },
  validUntilExplanation: {
    de: 'Wenn Sie einen Endzeitpunkt festlegen, muss der Test bis zu diesem Zeitpunkt abgeschlossen werden.',
    en: 'If you set an end time, the test must be completed by that point.'
  },
  timerExplanation: {
    de: 'Legen Sie fest, wie lange die Teilnehmer Zeit haben, den Test zu bearbeiten, nachdem Sie den Timer gestartet haben.',
    en: 'Specify how much time the candidates have to complete the test after you start the timer.'
  },
  startedDurationExplanation: {
    de: 'Legen Sie fest, wie lange die Teilnehmer nach Beginnen des Tests Zeit haben, ihn zu beenden.',
    en: 'Specify how much time the candidates have to finish the test after starting it.'
  },
  crossTestReferenceExplanation: {
    de: 'Ein Crosstest muss einem Referenztest zugeordnet sein. Der Referenztest ist der unbeaufsichtigte Leistungstest, dessen Ergebnis Sie mithilfe des Crosstests überprüfen möchten.',
    en: 'A cross-test must be assigned to a reference test. The reference test is the unsupervised performance test whose results you want to verify using the cross-test.'
  },
  statisticsRequirement: {
    de: 'Bitte geben Sie an, ob dieser Test vor Ort unter Aufsicht durchgeführt wird. Diese Angabe brauchen wir für unsere Statistiken.',
    en: 'Please indicate whether this test will be conducted on-site under supervision. We need this information for our statistics.'
  },
  useChildItemsExplanation: {
    de: 'Bei zufälliger Aufgabenwahl bearbeiten die Kandidaten nicht dieselben, aber vergleichbare Aufgaben. Dies erschwert Täuschungsversuche.',
    en: 'With random item selection enabled, candidates do not get the same items, but rather comparable ones. This makes cheating more difficult.'
  },
  hubExplanation: {
    de: 'Kandidaten können sichtbare Tests von der Testübersicht aus starten. Das ist sinnvoll, wenn Kandidaten mehrere Tests durchführen sollen.',
    en: 'Candidates can start visible tests from the test overview page. This is useful when candidates need to complete multiple tests.'
  },
  chooseTestLanguage: {
    de: 'Bitte wählen Sie mindestens eine Sprache aus. Wenn Sie mehrere Sprachen auswählen, können die Teilnehmer die Sprache vor Beginn des Tests selbst wählen.',
    en: 'Please select at least one language. If you select more than one language, participants can choose the language themselves before starting the test.'
  },
  dateAndTime: {
    de: 'Datum/Uhrzeit',
    en: 'Date/Time'
  },
  time: {
    de: 'Zeit',
    en: 'Time'
  },
  day: {
    de: 'Tag',
    en: 'day'
  },
  days: {
    de: 'Tage',
    en: 'days'
  },
  hours: {
    de: 'Stunden',
    en: 'hours'
  },
  minutes: {
    de: 'Minuten',
    en: 'minutes'
  },
  minutesShort: {
    de: 'Min.',
    en: 'min'
  },
  hoursShort: {
    de: 'Std.',
    en: 'h'
  },
  minimumDurationIs: {
    de: 'Die Mindestdauer beträgt',
    en: 'The minimum duration is'
  },
  additionalOptions: {
    de: 'Weitere Optionen',
    en: 'Additional Settings'
  },
  selectReportModules: {
    de: 'Reportmodule auswählen',
    en: 'Select Report Modules'
  },
  basicModule: {
    de: 'Basis-Modul',
    en: 'Basic Module'
  },
  optionalModules: {
    de: 'Optionale Module',
    en: 'Optional Modules'
  },
  resultsOverview: {
    de: 'Ergebnisübersicht',
    en: 'Results Overview'
  },
  meaningfulPersonalityProfile: {
    de: 'Liefert ein aussagekräftiges Persönlichkeitsprofil. In jedem Test standardmäßig enthalten.',
    en: 'Provides a meaningful personality profile. Included in every test by default.'
  },
  meaningfulPerformanceProfile: {
    de: 'Liefert ein aussagekräftiges Leistungsprofil inkl. Empfehlung und Auffälligkeiten. In jedem Test standardmäßig enthalten.',
    en: 'Provides a meaningful performance profile including recommendations and anomalies. Included in every test by default.'
  },
  meaningfulInterestProfile: {
    de: 'Aussagekräftiges Interessenprofil für alle sechs RIASEC-Dimensionen. In jedem Test standardmäßig enthalten.',
    en: 'Provides a meaningful interest profile for all six RIASEC dimensions. Included in every test by default.'
  },
  crossTestResult: {
    de: 'Crosstest-Ergebnis',
    en: 'Cross-Test Result'
  },
  prePostTestComparison: {
    de: 'Liefert einen Vergleich von Vor- und Nachtest inkl. Empfehlung und Auffälligkeiten. In jedem Test standardmäßig enthalten.',
    en: 'Provides a comparison of pre-test and post-test results including recommendations and anomalies. Included in every test by default.'
  },
  shortExplanationsInterestDimensions: {
    de: 'Liefert kurze Erklärungen zu den einzelnen Interessendimensionen und Messergebnissen.',
    en: 'Provides short explanations of the individual interest dimensions and measurement results.'
  },
  resultDescription: {
    de: 'Ergebnisbeschreibung',
    en: 'Result Description'
  },
  shortExplanationsPersonalityFacets: {
    de: 'Liefert kurze Erklärungen zu den einzelnen Persönlichkeitsfacetten und Messergebnissen.',
    en: 'Provides short explanations of the individual personality facets and measurement results.'
  },
  explanationGoalsRadarModule: {
    de: 'Stellt das Interessenprofil in einem übersichtlichen Netzdiagramm dar.',
    en: 'Displays the interest profile in a clear radar chart.'
  },
  detailedBehavioralHints: {
    de: 'Gibt detaillierte Hinweise auf zu erwartendes Verhalten im beruflichen Alltag.',
    en: 'Provides detailed hints on expected behavior in the professional environment.'
  },
  relevantInterviewQuestions: {
    de: 'Liefert passende Interviewfragen, mit denen Sie den Testergebnissen auf den Grund gehen.',
    en: 'Provides relevant interview questions to delve into the test results.'
  },
  compareCandidatesWithProfile: {
    de: 'Vergleichen Sie Ihre Kandidaten anhand der Übereinstimmung mit Ihrem Anforderungsprofil.',
    en: 'Compare your candidates based on their alignment with your requirements profile.'
  },
  participantFeedback: {
    de: 'Teilnehmer-Feedback',
    en: 'Participant Feedback'
  },
  resultFeedbackForParticipants: {
    de: 'Ergebnis-Rückmeldung für Ihre Teilnehmer. Enthält Begleittext zur Interpretation und keine Details zu Auswahlkriterien.',
    en: 'Result feedback for your participants. Includes accompanying text for interpretation and no details about selection criteria.'
  },
  statisticsAndAnomalies: {
    de: 'Statistiken und Auffälligkeiten',
    en: 'Statistics and Anomalies'
  },
  detailedTestStatistics: {
    de: 'Liefert detaillierte Teststatistiken zu übersprungenen Aufgaben, Abwesenheiten und Hilfsmitteln.',
    en: 'Provides detailed test statistics on skipped tasks, absences, and aids.'
  },
  jobMatching: {
    de: 'Job-Matching',
    en: 'Job Matching'
  },
  radarPlot: {
    de: 'Netzdiagramm',
    en: 'Radar Plot'
  },
  potentialsAndRisks: {
    de: 'Potenziale und Risiken',
    en: 'Potentials and Risks'
  },
  interviewQuestions: {
    de: 'Interviewfragen',
    en: 'Interview Questions'
  },
  openTextResponses: {
    de: 'Freitext-Antworten',
    en: 'Open Text Responses'
  },
  inclusive: {
    de: 'inklusive',
    en: 'inclusive'
  },
  freeInDemo: {
    de: 'in Demo kostenlos',
    en: 'free in demo'
  },
  selectedTest: {
    de: 'Gewählter Test',
    en: 'Selected Test'
  },
  processSuccessfullyCreated: {
    de: 'Verfahren erfolgreich erstellt',
    en: 'Process successfully created'
  },
  processSuccessfullyDuplicated: {
    de: 'Verfahren erfolgreich dupliziert',
    en: 'Process successfully duplicated'
  },
  addTestNow: {
    de: 'Sie können nun einen Test hinzufügen.',
    en: 'You can now add a test.'
  },
  defineAccessAndEditing: {
    de: 'Legen Sie hier fest, wer das neue Verfahren einsehen und bearbeiten kann. Administratoren können alle Verfahren bearbeiten.',
    en: 'Specify here who can view and edit the new process. Administrators can edit all processes.'
  },
  processingInProgress: {
    de: 'Bearbeitung wird durchgeführt...',
    en: 'Processing in progress...'
  },
  creditChargeInProgress: {
    de: 'Credits werden aufgeladen...',
    en: 'Credits are being charged...'
  },
  paymentFailed: {
    de: 'Zahlung konnte nicht durchgeführt werden.',
    en: 'Payment could not be processed.'
  },
  cardValidUntil: {
    de: 'Gültig bis',
    en: 'Valid until'
  },
  paymentInProgress: {
    de: 'Zahlung wird durchgeführt...',
    en: 'Payment is being processed...'
  },
  creditPrice: {
    de: 'Creditpreis',
    en: 'Credit Price'
  },
  testSuccessfullyCreated: {
    de: 'Test erfolgreich erstellt',
    en: 'Test successfully created'
  },
  addParticipantsNow: {
    de: 'Sie können nun Teilnehmer hinzufügen.',
    en: 'You can now add participants.'
  },
  modules: {
    de: 'Module',
    en: 'Modules'
  },
  processesAndTests: {
    de: 'Verfahren und Tests',
    en: 'Processes and Tests'
  },
  selectProcessToAddParticipants: {
    de: 'Um Teilnehmer hinzuzufügen, müssen Sie zunächst ein Verfahren auswählen.',
    en: 'To add participants, you must first select a process.'
  },
  selectAtLeastOneTest: {
    de: 'Bitte wählen Sie mindestens einen Test, dem Sie Teilnehmer hinzufügen möchten.',
    en: 'Please select at least one test to which you want to add participants.'
  },
  dataSource: {
    de: 'Datenquelle',
    en: 'Data Source'
  },
  createBlankParticipants: {
    de: 'Blanko Teilnehmer anlegen',
    en: 'Create Blank Participants'
  },
  blankParticipantsExplanaation: {
    de: 'Legen Sie eine beliebige Zahl neuer Teilnehmer an und verschicken die Zugangsdaten selbst. Die persönlichen Daten geben Ihre Teilnehmer im Test ein.',
    en: 'Create any number of new participants and send the access data yourself. Participants will enter their personal information in the test.'
  },
  uploadParticipantList: {
    de: 'Teilnehmerliste hochladen',
    en: 'Upload Participant List'
  },
  uploadParticipantListExplanation: {
    de: 'Laden Sie eine bereits vorhandene Teilnehmerliste in unser System. So können Sie Teilnehmer direkt aus dem Kundenbereich zum Test einladen.',
    en: 'Upload an existing participant list into our system. This way, you can directly invite participants to the test from the customer area.'
  },
  logoutSuccess: {
    de: 'Sie haben sich erfolgreich ausgeloggt.',
    en: 'You have successfully logged out.'
  },
  sessionEnded: {
    de: 'Ihre Sitzung wurde beendet.',
    en: 'Your session has ended.'
  },
  incorrectCredentials: {
    de: 'Bitte überprüfen Sie Ihre Zugangsdaten und versuchen es erneut.',
    en: 'Please check your login credentials and try again.'
  },
  userBlocked: {
    de: 'Ihr Account wurde gesperrt. Bitte kontaktieren Sie den Support.',
    en: 'Your account has been blocked. Please contact support.'
  },
  participantsAndPrivacy: {
    de: 'Teilnehmerzahl und Datenschutz',
    en: 'Number of Participants and Privacy'
  },
  howManyParticipants: {
    de: 'Wie viele Teilnehmer möchten Sie anlegen?',
    en: 'How many participants would you like to create?'
  },
  participantTestingInfo: {
    de: () => (
      <span>
        Für jeden Teilnehmer wird automatisch eine Testung je Test erstellt. Hierbei entstehen noch
        <span style={{ fontWeight: 600 }}> keine Kosten</span>, da Credits nur pro abgeschlossener Testung berechnet
        werden.
        <br />
        <br />
        Sie können auch eine individuelle Anzahl an Teilnehmern in das Feld „Ihre Auswahl“ eintragen.
      </span>
    ),
    en: () => (
      <span>
        For each participant, a test will be created automatically per test. There are
        <span style={{ fontWeight: 600 }}> no costs </span>incurred at this point, as credits are only charged per
        completed test.
        <br />
        <br />
        You can also enter a custom number of participants in the field „Your selection.“
      </span>
    )
  },
  dataRetentionInfo: {
    de: (timeSpan, days) => (
      <>
        Namen und E-Mail-Adressen werden nach{' '}
        <span style={{ fontWeight: 600, whiteSpace: 'nowrap' }}>{days > 30 ? timeSpan + 'n' : timeSpan}</span>{' '}
        automatisch gelöscht. Die Frist zur Anonymisierung können Sie jederzeit unter{' '}
        <span style={{ fontWeight: 600 }}>
          Menü {'>'} Einstellungen {'>'} Datenschutz
        </span>{' '}
        ändern.
      </>
    ),
    en: (timeSpan) => (
      <>
        Names and email addresses will be automatically deleted after{' '}
        <span style={{ fontWeight: 600, whiteSpace: 'nowrap' }}>{timeSpan}</span>. You can change the anonymization
        deadline at any time under{' '}
        <span style={{ fontWeight: 600 }}>
          Menu {'>'} Settings {'>'} Privacy
        </span>
        .
      </>
    )
  },
  participantNameEntry: {
    de: 'Die Teilnehmer geben ihren Namen zu Beginn des Tests selbst ein.',
    en: 'Participants enter their names themselves at the beginning of the test.'
  },
  participantIdentification: {
    de: 'Die Teilnehmer werden nur über ihre Teilnehmernummer identifiziert.',
    en: 'Participants are identified only by their participant number.'
  },
  yourSelection: {
    de: 'Ihre Auswahl',
    en: 'Your Selection'
  },
  uploadCSVInstructions: {
    de: 'Bitte laden Sie Ihre Teilnehmerliste als Excel- oder CSV-Datei hoch.',
    en: 'Please upload your participant list as an Excel or CSV file.'
  },
  downloadSample: {
    de: 'Muster downloaden',
    en: 'Download sample'
  },
  dragOrClickToUpload: {
    de: 'Teilnehmerliste hierhin ziehen oder klicken, um eine Datei auszuwählen.',
    en: 'Drag participant list here or click to select a file.'
  },
  assignColumns: {
    de: 'Spalten zuordnen',
    en: 'Assign Columns'
  },
  rearrangeColumnsInfo: {
    de: 'Hier können Sie die Tabellenspalten neu zuordnen, falls die automatische Zuordnung falsch sein sollte.',
    en: 'Here you can rearrange the table columns if the automatic assignment is incorrect.'
  },
  invoiceData: {
    de: 'Rechnungsdaten',
    en: 'Invoice Data'
  },
  sendByEmailTo: {
    de: 'per E-Mail an',
    en: 'By Email to'
  },
  paymentMethodLabel: {
    de: 'Zahlungsart',
    en: 'Payment Method'
  },
  payment: {
    de: 'Zahlung',
    en: 'Payment'
  },
  importantNote: {
    de: 'Wichtiger Hinweis',
    en: 'Important Note'
  },
  creditSystemInfo: {
    de: 'Bei der Durchführung der von Ihnen gewählten Tests werden Credits von Ihrem Konto abgebucht. Sollte dabei ein negatives Guthaben entstehen, erhalten Sie Anfang des Monats eine Rechnung über den ausstehenden Betrag.',
    en: 'When conducting the tests you have selected, credits will be deducted from your account. If this results in a negative balance, you will receive an invoice for the outstanding amount at the beginning of the month.'
  },
  creditsFAQ: {
    de: 'Sie haben Fragen zum Credit-System? Hier finden Sie unsere',
    en: 'Do you have questions about the credit system? Here you can find our'
  },
  termsAndConditions: {
    de: 'AGB',
    en: 'Terms and Conditions'
  },
  termsAndConditionsLink: {
    de: (agbLink) => <>Es gelten unsere {agbLink}.</>,
    en: (agbLink) => <>Our {agbLink} apply.</>
  },
  add: {
    de: 'Hinzufügen',
    en: 'Add'
  },
  ptCreatedHeadline: {
    de: (pCount, ptCount) =>
      `${pCount} Teilnehmer mit je ${ptCount} ${ptCount === 1 ? 'Testung' : 'Testungen'} erstellt`,
    en: (pCount, ptCount) =>
      `${pCount} ${pCount === 1 ? 'Participant' : 'Participants'} created with ${ptCount} 
        ${ptCount === 1 ? 'Testing' : 'Testings'} each`
  },
  ptAddedHeadline: {
    de: (pCount, testCount) => `${pCount} Teilnehmer zu ${testCount} ${testCount === 1 ? 'Test' : 'Tests'} hinzugefügt`,
    en: (pCount, testCount) => `${pCount} Participant added to ${testCount} ${testCount === 1 ? 'Test' : 'Tests'}`
  },
  nextSteps: {
    de: 'Wie geht es weiter?',
    en: 'Next Steps'
  },
  downloadCredentials: {
    de: 'Zugangsdaten herunterladen',
    en: 'Download Credentials'
  },
  accessTestInstructions: {
    de: (showParticipantNumber, elektryonLink, accessDataLink) => (
      <>
        <span>Um zum Test zu gelangen, können sich Ihre Teilnehmer entweder mit der </span>
        <span>{showParticipantNumber ? 'Teilnehmer-Nr. ' : 'TAN '}</span>
        <span>
          unter {elektryonLink} einloggen oder einen Testlink nutzen. Diese Daten können Sie in den Sektionen
          „Teilnehmer“ und „Testungen“ auf der linken Seite oder direkt {accessDataLink} herunterladen.
        </span>
      </>
    ),
    en: (showParticipantNumber, elektryonLink, accessDataLink) => (
      <>
        <span>To access the test, participants can either log in with the </span>
        <span>{showParticipantNumber ? 'Participant Number ' : 'TAN '}</span>
        <span>
          at {elektryonLink} or use a test link. You can download the access data in the „Participants“ and „Testings“
          sections on the left side or directly {accessDataLink}
        </span>
      </>
    )
  },
  sendTestInvitations: {
    de: 'Testeinladungen verschicken',
    en: 'Send Test Invitations'
  },
  sendInvitationsInstructions: {
    de: 'Die Einladungen zum Test können Sie z.B. über Outlook oder ein externes Bewerbermanagementsystem verschicken.',
    en: 'You can send test invitations using tools like Outlook or an external applicant management system.'
  },
  retrieveResults: {
    de: 'Ergebnisse abrufen',
    en: 'Retrieve Results'
  },
  retrieveResultsInstructions: {
    de: 'Die Testergebnisse liegen sofort nach Abschluss des Tests vor. Sie finden sie u.a. in den Sektionen „Teilnehmer“, „Testungen“ und „Dashboard“.',
    en: 'Test results are available immediately after the test is completed. You can find them in sections such as „Participants“, „Testings“, and „Dashboard“.'
  },
  createdParticipantsAndTestings: {
    de: 'Erstellte Teilnehmer und Testungen',
    en: 'Created Participants and Testings'
  },
  sendInvitationsFromDashboard: {
    de: 'Sie können die Einladungen auch direkt aus dem Kundenbereich versenden.',
    en: 'You can also send the invitations directly from the customer dashboard.'
  },
  here: {
    de: 'hier',
    en: 'here'
  },
  goToParticipantList: {
    de: 'zur Teilnehmerliste',
    en: 'Go to Participant List'
  },
  invoice: {
    de: 'Rechnung',
    en: 'Invoice'
  },
  creditCard: {
    de: 'Kreditkarte',
    en: 'Credit Card'
  },
  enterCreditCardData: {
    de: 'Bitte geben Sie hier Ihre Kreditkarten-Daten ein.',
    en: 'Please enter your credit card data here.'
  },
  creditCardHint: {
    de: 'Sichere Abwicklung durch externen Zahlungspartner.',
    en: 'Secure processing through an external payment partner.'
  },
  receiveInvoiceByEmail: {
    de: 'Sie erhalten eine Rechnung per E-Mail.',
    en: 'You will receive an invoice by email.'
  },
  redirectToPayPal: {
    de: 'Sie werden zum Bezahlen zu PayPal weitergeleitet.',
    en: 'You will be redirected to PayPal for payment.'
  },
  creditCount: {
    de: 'Anzahl Credits',
    en: 'Number of Credits'
  },
  creditPackages: {
    de: 'Credit-Pakete',
    en: 'Credit Packages'
  },
  useCreditCalculator: {
    de: 'Wenn Sie ermitteln möchten, wie viele Credits Sie für Ihr Projekt benötigen, können Sie unseren Credit-Rechner nutzen.',
    en: 'If you want to determine how many credits you need for your project, you can use our credit calculator.'
  },
  enterCustomCreditAmount: {
    de: 'Sie können auch eine individuelle Anzahl an Credits in das Feld „Anzahl Credits“ eintragen.',
    en: 'You can also enter a custom number of credits in the „Number of Credits“ field.'
  },
  unitPrice: {
    de: 'Einzelpreis',
    en: 'Unit Price'
  },
  quantityDiscount: {
    de: 'Mengenrabatt',
    en: 'Volume Discount'
  },
  creditCalculator: {
    de: 'Credit-Rechner',
    en: 'Credit Calculator'
  },
  subtotal: {
    de: 'Zwischensumme',
    en: 'Subtotal'
  },
  minus: {
    de: 'abzgl.',
    en: 'Minus'
  },
  discount: {
    de: 'Rabatt',
    en: 'Discount'
  },
  netAmount: {
    de: 'Netto',
    en: 'Net'
  },
  each: {
    de: 'Stk.',
    en: 'each'
  },
  youSave: {
    de: 'Sie sparen',
    en: 'You save'
  },
  product: {
    de: 'Produkt',
    en: 'Product'
  },
  amount: {
    de: 'Anzahl',
    en: 'Amount'
  },
  pleaseSelect: {
    de: 'bitte wählen',
    en: 'please select'
  },
  calculatedAmountField: {
    de: 'Der errechnete Betrag erscheint oben als zusätzliches Auswahlfeld.',
    en: 'The calculated amount appears as an additional selection field at the top.'
  },
  validBillingAddress: {
    de: 'Um die Zahlung abwickeln zu können, brauchen wir gültige Rechnungsdaten. Die Rechnung senden wir Ihnen per E-Mail zu.',
    en: 'To process the payment, we need valid invoice data. We will send the invoice to you by email.'
  },
  paymentMethodAndInvoiceData: {
    de: 'Zahlungsart und Rechnungsdaten',
    en: 'Payment Method and Invoice Data'
  },
  reviewOrder: {
    de: 'Bestellung prüfen',
    en: 'Review Order'
  },
  order: {
    de: 'Bestellung',
    en: 'Order'
  },
  invoiceAmount: {
    de: 'Rechnungsbetrag',
    en: 'Invoice Amount'
  },
  legalInformation: {
    de: 'Rechtliches',
    en: 'Legal Information'
  },
  grossSum: {
    de: 'Summe (brutto)',
    en: 'Gross Total'
  },
  netSum: {
    de: 'Summe (netto)',
    en: 'Net Total'
  },
  vat: {
    de: 'USt.',
    en: 'VAT'
  },
  creditsSuccessfullyCharged: {
    de: 'Credits erfolgreich aufgeladen.',
    en: 'Credits successfully charged.'
  },
  unableToChargeCredits: {
    de: 'Credits konnten nicht aufgeladen werden.',
    en: 'Credits could not be charged.'
  },
  thankYouForOrder: {
    de: 'Vielen Dank für Ihre Bestellung!',
    en: 'Thank you for your order!'
  },
  newAccountBalance: {
    de: 'Ihr neuer Kontostand beträgt',
    en: 'Your new account balance is'
  },
  goToOverview: {
    de: 'zur Übersicht',
    en: 'go to overview'
  },
  send: {
    de: 'Senden',
    en: 'Send'
  },
  sendMails: {
    de: 'E-Mails senden',
    en: 'Send Emails'
  },
  reportModuleInterviewQuestionsForTraits: {
    de: 'Reportmodul Interviewfragen für Traits',
    en: 'Report Module Interview Questions for Traits'
  },
  reportModulePotentialsAndRisksForTraits: {
    de: 'Reportmodul Potenziale und Risiken für Traits',
    en: 'Report Module Potentials and Risks for Traits'
  },
  reportModuleJobMatching: {
    de: 'Reportmodul Job-Matching',
    en: 'Report Module Job Matching'
  },
  feedbackReport: {
    de: 'Feedback-Report',
    en: 'Feedback Report'
  },
  changePermissions: {
    de: (num) => `Zugriffsrechte für ${num} Nutzer ändern`,
    en: (num) => `Change Permissions for ${num} ${num === 1 ? 'User' : 'Users'}`
  },
  newPermissionsReview: {
    de: (num) => `Neue Berechtigungen für ${num} Nutzer`,
    en: (num) => `New Permissions for ${num} ${num === 1 ? 'User' : 'Users'}`
  },
  noChanges: {
    de: 'keine Änderung',
    en: 'no changes'
  },
  affectedProcesses: {
    de: 'Betroffene Verfahren',
    en: 'Affected Processes'
  },
  permissionsChangedSuccessfully: {
    de: 'Rechte erfolgreich geändert.',
    en: 'Permissions changed successfully.'
  },
  addParticipantsToMultipleTests: {
    de: 'Sie können Teilnehmer zu mehreren Tests hinzufügen. Bitte beachten Sie, dass Sie einen Teilnehmer einem Test nur ein Mal zuweisen können.',
    en: 'You can assign participants to multiple tests. Please note that you can assign a participant to a test only once.'
  },
  cannotSelectTestInArchive: {
    de: 'Sie können diesen Test nicht auswählen, weil er im Archiv liegt.',
    en: 'You cannot select this test because it is archived.'
  },
  cannotSelectTestDoubleAssignment: {
    de: 'Sie können diesen Test nicht auswählen, weil Teilnehmer nicht doppelt zugewiesen werden können.',
    en: 'You cannot select this test because participants cannot take the same test more than once.'
  },
  numberOfParticipants: {
    de: 'Anzahl Teilnehmer',
    en: 'Number of Participants'
  },
  free: {
    de: 'kostenlos',
    en: 'free'
  },
  selectedParticipants: {
    de: 'Gewählte Teilnehmer',
    en: 'Selected Participants'
  },
  emailCc: {
    de: 'Kopie an',
    en: 'CC email'
  },
  noPotentialsTest: {
    de: 'kein Leistungstest',
    en: 'no Potentials test'
  },
  noWritePermissions: {
    de: 'keine Schreibrechte',
    en: 'no write permissions'
  },
  testInArchiveCannotBeEdited: {
    de: 'Diese Testung ist archiviert und kann nicht bearbeitet werden.',
    en: 'This test is archived and cannot be edited.'
  },
  noEditorRightsForTest: {
    de: 'Diese Testung kann nicht bearbeitet werden, da Sie für das Verfahren keine Editor-Rechte besitzen.',
    en: 'This test cannot be edited because you do not have editor rights for the process.'
  },
  settingsCannotBeChangedAfterCompletion: {
    de: 'Einige Einstellungen können nicht mehr geändert werden, weil diese Testung bereits abgeschlossen wurde.',
    en: 'Some settings cannot be changed anymore because this test has already been completed.'
  },
  settingsCannotBeChangedAfterDeactivation: {
    de: 'Einige Einstellungen können nicht mehr geändert werden, weil diese Testung deaktiviert wurde.',
    en: 'Some settings cannot be changed anymore because this test has been deactivated.'
  },
  settingsCannotBeChangedAfterStart: {
    de: 'Einige Einstellungen können nicht mehr geändert werden, weil diese Testung bereits gestartet wurde.',
    en: 'Some settings cannot be changed anymore because this test has already been started.'
  },
  processSettingsCannotBeEditedInArchive: {
    de: 'Einstellungen können nicht bearbeitet werden, weil dieses Verfahren archiviert ist.',
    en: 'Settings cannot be edited because this process is archived.'
  },
  processNoEditorPermissions: {
    de: 'Einstellungen können nicht bearbeitet werden, da Sie für dieses Verfahren keine Editor-Rechte besitzen.',
    en: 'Settings cannot be edited because you do not have editor rights for this process.'
  },
  irreversibleStep: {
    de: 'Dieser Schritt kann nicht rückgängig gemacht werden.',
    en: 'This step cannot be undone.'
  },
  deactivatedTestsCannotBePerformed: {
    de: 'Deaktivierte Testungen können nicht mehr durchgeführt werden.',
    en: 'Deactivated tests cannot be performed anymore.'
  },
  unfinishedTestsDeactivated: {
    de: 'Nicht abgeschlossene Testungen werden dabei deaktiviert und können nicht mehr durchgeführt werden.',
    en: 'Unfinished tests will be deactivated and cannot be performed anymore.'
  },
  unarchivedProcessesFromArchive: {
    de: 'Sollten zugeordnete Verfahren archiviert sein, werden diese ebenfalls dearchiviert.',
    en: 'If associated processes are archived, they will also be unarchived.'
  },
  unarchivedTestsAndProcessesFromArchive: {
    de: 'Sollten zugeordnete Tests oder Verfahren archiviert sein, werden diese ebenfalls dearchiviert.',
    en: 'If associated tests or processes are archived, they will also be unarchived.'
  },
  unarchivedParticipantsTestsAndProcessesFromArchive: {
    de: 'Sollten zugeordnete Teilnehmer, Tests oder Verfahren archiviert sein, werden diese ebenfalls dearchiviert.',
    en: 'If associated participants, tests, or processes are archived, they will also be unarchived.'
  },
  testSettingsCannotBeEditedInArchive: {
    de: 'Einstellungen können nicht bearbeitet werden, weil dieser Test archiviert ist.',
    en: 'Settings cannot be edited because this test is archived.'
  },
  noEditorRightsForTestSettings: {
    de: 'Einstellungen können nicht bearbeitet werden, da Sie für diesen Test keine Editor-Rechte besitzen.',
    en: 'Settings cannot be edited because you do not have editor rights for this test.'
  },
  personalDataCannotBeEditedInArchive: {
    de: 'Persönliche Daten können nicht bearbeitet werden, da dieser Teilnehmer archiviert ist.',
    en: 'Personal data cannot be edited because this participant is archived.'
  },
  personalDataCannotBeEditedAnonymizedParticipant: {
    de: 'Persönliche Daten können nicht bearbeitet werden, da dieser Teilnehmer anonym ist.',
    en: 'Personal data cannot be edited because this participant is anonymous.'
  },
  noEntityFound: {
    de: (entity) => `Keine ${entity} gefunden`,
    en: (entity) => (
      <>
        No <span style={{ textTransform: 'lowercase' }}>{entity}</span> found
      </>
    )
  },

  noResultsYet: {
    de: 'Noch keine Ergebnisse',
    en: 'No results yet'
  },
  noResults: {
    de: 'Keine Einträge gefunden',
    en: 'No results found'
  },
  noTestResultsYet: {
    de: 'Es liegen noch keine Testergebnisse vor.',
    en: 'There are no test results available yet.'
  },
  noProcesses: {
    de: 'Keine Verfahren gefunden',
    en: 'No Processes found'
  },
  noParticipantsCreated: {
    de: 'Keine Teilnehmer erstellt.',
    en: 'No participants created.'
  },
  emailTemplate: {
    de: 'E-Mail-Vorlage',
    en: 'Email Template'
  },
  emailType: {
    de: 'E-Mail-Typ',
    en: 'Email Type'
  },
  emailSubject: {
    de: 'E-Mail Betreff',
    en: 'Email Subject'
  },
  subject: {
    de: 'Betreff',
    en: 'Subject'
  },
  sendSampleEmail: {
    de: 'Um eine Muster-E-Mail zu senden, geben Sie hier eine E-Mail-Adresse ein und drücken auf „Jetzt senden“.',
    en: 'To send a sample email, enter an email address here and click „Send Now“.'
  },
  createAndSaveNewTemplates: {
    de: 'Wählen Sie eine E-Mail-Vorlage aus und passen diese an Ihre Anforderungen an. Sie können Ihre Änderungen unten als neue Vorlage speichern.',
    en: 'Select an email template and customize it to suit your requirements. You can save your changes as a new template below.'
  },
  freeTextTop: {
    de: 'Freitext oben',
    en: 'Freetext Top'
  },
  freeTextBottom: {
    de: 'Freitext unten',
    en: 'Freetext Bottom'
  },
  footerLeft: {
    de: 'Footer links',
    en: 'Footer left'
  },
  footerCenter: {
    de: 'Footer mitte',
    en: 'Footer center'
  },
  footerRight: {
    de: 'Footer rechts',
    en: 'Footer right'
  },
  accessDataPlaceholder: {
    de: 'Hier werden die Zugangsdaten automatisch eingefügt.',
    en: 'Here, the access data is automatically inserted.'
  },
  invitationMailSubject: {
    de: 'Einladung zum Onlinetest',
    en: 'Invitation to Online Test'
  },
  reminderMailSubject: {
    de: 'Erinnerung: Teilnahme am Onlinetest',
    en: 'Reminder: Participation in Online Test'
  },
  reminderMailSubjectCasual: {
    de: 'Nicht vergessen: Dein Onlinetest wartet',
    en: `Don’t Forget: Your Online Test is Waiting`
  },
  defaultInvitation: {
    de: 'Einladung förmlich',
    en: 'Invitation formal'
  },
  defaultReminder: {
    de: 'Erinnerung förmlich',
    en: 'Reminder formal'
  },
  defaultInvitationCasual: {
    de: 'Einladung persönlich',
    en: 'Invitation casual'
  },
  defaultReminderCasual: {
    de: 'Erinnerung persönlich',
    en: 'Reminder casual'
  },
  defaultBottomText: {
    de: (customerName) =>
      `<p>Wir wünschen Ihnen viel Erfolg beim Test und freuen uns auf Ihre Rückmeldung.</p><p>Mit freundlichen Grüßen</p><p>${customerName}</p>`,
    en: (customerName) =>
      `<p>We wish you the best of luck with the test and look forward to hearing from you.</p><p>Kind regards,</p><p>${customerName}</p>`
  },
  defaultBottomTextCasual: {
    de: (customerName) =>
      `<p>Viel Erfolg beim Test – wir freuen uns auf deine Ergebnisse!</p><p>Beste Grüße</p><p>${customerName}</p>`,
    en: (customerName) =>
      `<p>Good luck with the test – we’re looking forward to seeing your results!</p><p>Best regards,</p><p>${customerName}</p>`
  },
  defaultTopTextInvitation: {
    de: `<p>vielen Dank für Ihre Bewerbung. Im Rahmen des Auswahlverfahrens ist ein Onlinetest vorgesehen, zu dem wir Sie hiermit herzlich einladen möchten.</p><p>Um den Test zu bearbeiten, loggen Sie sich bitte mit Ihren persönlichen Zugangsdaten ein:</p>`,
    en: `<p>Thank you very much for your application. As part of the selection process, an online test is planned, and we would like to invite you to participate.</p><p>To complete the test, please log in using your personal access credentials:</p>`
  },
  defaultTopTextReminder: {
    de: `<p>vor kurzem haben wir Ihnen einen Link zum Onlinetest geschickt. Wir möchten Sie gerne daran erinnern, diesen zu absolvieren, da Ihre Bewerbung sonst nicht berücksichtigt werden kann.</p><p>Die Zugangsdaten finden Sie hier:</p>`,
    en: `<p>Recently, we sent you a link to the online test. We would like to remind you to complete it, as your application cannot be considered otherwise.</p><p>You can find your access credentials here:</p>`
  },
  defaultTopTextInvitationCasual: {
    de: `<p>vielen Dank für deine Bewerbung! Im nächsten Schritt unseres Auswahlverfahrens wartet ein Onlinetest auf dich, zu dem wir dich herzlich einladen.</p><p>Um den Test zu starten, logg dich einfach mit deinen Zugangsdaten ein:</p>`,
    en: `<p>Thanks a lot for your application! The next step in our selection process is an online test, and we’re excited to invite you to take part.</p><p>To get started, just log in using your access details:</p>`
  },
  defaultTopTextReminderCasual: {
    de: `<p>vor kurzem haben wir dir einen Link zum Onlinetest geschickt. Wir wollten dich nochmal daran erinnern, am Test teilzunehmen, damit wir deine Bewerbung weiter berücksichtigen können.</p><p>Deine Zugangsdaten findest du hier:</p>`,
    en: `<p>We recently sent you a link to the online test. Just a quick reminder to complete it so we can continue reviewing your application.</p><p>You can find your access credentials here:</p>`
  },
  saveTemplate: {
    de: 'Vorlage speichern',
    en: 'Save Template'
  },
  saveAsNewTemplate: {
    de: 'Als neue Vorlage speichern',
    en: 'Save as new template'
  },
  editCurrentTemplate: {
    de: 'Bestehende Vorlage aktualisieren',
    en: 'Edit current template'
  },
  copy: {
    de: 'Kopie',
    en: 'Copy'
  },
  dataForPreview: {
    de: 'Daten für Vorschau',
    en: 'Data for Preview'
  },
  sender: {
    de: 'Absender',
    en: 'Sender'
  },
  to: {
    de: 'An',
    en: 'To'
  },
  deleteTemplate: {
    de: 'Vorlage löschen',
    en: 'Delete Template'
  },
  participantMale: {
    de: 'Teilnehmer',
    en: 'participant'
  },
  participantFemale: {
    de: 'Teilnehmerin',
    en: 'participant'
  },
  participantDiverse: {
    de: 'Teilnehmer/in',
    en: 'participant'
  },
  hello: {
    de: 'Hallo',
    en: 'Hello'
  },
  salutationCasualMale: {
    de: 'Lieber',
    en: 'Dear'
  },
  salutationCasualFemale: {
    de: 'Liebe',
    en: 'Dear'
  },
  salutationCasualDiverse: {
    de: 'Liebe/r',
    en: 'Dear'
  },
  salutationPoliteMale: {
    de: 'Sehr geehrter',
    en: 'Dear'
  },
  salutationPoliteFemale: {
    de: 'Sehr geehrte',
    en: 'Dear'
  },
  salutationPoliteDiverse: {
    de: 'Sehr geehrter/r',
    en: 'Dear'
  },
  replyTo: {
    de: 'Antwort an',
    en: 'Reply-to'
  },
  replyToName: {
    de: 'Antwort an: Name',
    en: 'Reply-To Name'
  },
  replyToEmail: {
    de: 'Antwort an: E-Mail',
    en: 'Reply-To Email'
  },
  replyToDescription: {
    de: 'Wenn der Empfänger auf „Antworten“ klickt, wird die Antwort an die hier angegebene Adresse gesendet.',
    en: 'When the recipient clicks on “Reply”, the reply will be sent to the address provided here.'
  },
  politeLastName: {
    de: () => (
      <>
        Sehr geehrter Herr <span className="template-placeholder">Nachname</span>,
      </>
    ),
    en: () => (
      <>
        Dear Mr. <span className="template-placeholder">Lastname</span>,
      </>
    )
  },
  casualLastName: {
    de: () => (
      <>
        Lieber Herr <span className="template-placeholder">Nachname</span>,
      </>
    ),
    en: () => (
      <>
        Dear Mr. <span className="template-placeholder">Lastname</span>,
      </>
    )
  },
  casualFirstName: {
    de: () => (
      <>
        Lieber <span className="template-placeholder">Vorname</span>,
      </>
    ),
    en: () => (
      <>
        Dear <span className="template-placeholder">Firstname</span>,
      </>
    )
  },
  helloFirstName: {
    de: () => (
      <>
        Hallo <span className="template-placeholder">Vorname</span>,
      </>
    ),
    en: () => (
      <>
        Hello <span className="template-placeholder">Firstname</span>,
      </>
    )
  },
  changed: {
    de: 'geändert',
    en: 'changed'
  },
  sendNow: {
    de: 'Jetzt senden',
    en: 'Send Now'
  },
  senderAddress: {
    de: 'Absender-Adresse',
    en: 'Sender Address'
  },
  senderName: {
    de: 'Absender-Name',
    en: 'Sender Name'
  },
  numberOfEmails: {
    de: 'Anzahl E-Mails',
    en: 'Number of Emails'
  },
  participantsReceiveEmail: {
    de: 'Die folgenden Teilnehmer erhalten eine E-Mail',
    en: 'The following participants will receive an email'
  },
  startTimerAfterSending: {
    de: 'Timer nach E-Mail-Versand starten',
    en: 'Start Timer after sending'
  },
  confirmMailing: {
    de: (count, emailLabel) => `Sind Sie sicher, dass Sie ${count} ${emailLabel} versenden möchten?`,
    en: (count, emailLabel) => `Are you sure you want to send ${count} ${emailLabel}?`
  },
  emailsBeingSent: {
    de: 'E-Mails werden verschickt',
    en: 'Emails are being sent'
  },
  emailsSent: {
    de: 'Mails verschickt',
    en: 'emails sent'
  },
  wishingYouGoodResults: {
    de: 'Wir wünschen Ihnen gute Ergebnisse!',
    en: 'We wish you good results!'
  },
  notAllEmailsSent: {
    de: 'Es wurden nicht alle E-Mails verschickt. Bitte kontrollieren Sie die Empfängeradressen.',
    en: 'Not all emails were sent. Please check the recipient addresses.'
  },
  passwordChangeFailed: {
    de: 'Passwort konnte nicht geändert werden.',
    en: 'Password change failed.'
  },
  dataSuccessfullyChanged: {
    de: 'Daten wurden erfolgreich geändert.',
    en: 'Data was successfully changed.'
  },
  dataChangeFailed: {
    de: 'Daten konnten nicht geändert werden.',
    en: 'Data change failed.'
  },
  fieldsSuccessfullyChanged: {
    de: 'Felder erfolgreich geändert.',
    en: 'Fields were successfully changed.'
  },
  fieldSuccessfullyDeleted: {
    de: 'Feld wurde erfolgreich gelöscht.',
    en: 'Field was successfully deleted.'
  },
  fieldDeletionFailed: {
    de: 'Feld konnte nicht gelöscht werden.',
    en: 'Field deletion failed.'
  },
  userSuccessfullyDeleted: {
    de: (user) => `Nutzer ${user} erfolgreich gelöscht.`,
    en: (user) => `User ${user} was successfully deleted.`
  },
  userDeletionFailed: {
    de: (user) => `Nutzer ${user} konnte nicht gelöscht werden.`,
    en: (user) => `Deletion of user ${user} failed.`
  },
  copiedToClipboard: {
    de: (elem) => `${elem} erfolgreich in die Zwischenablage kopiert.`,
    en: (elem) => `${elem} copied to clipboard successfully.`
  },
  copyToClipboardFailed: {
    de: (elem) => `${elem} konnte nicht kopiert werden.`,
    en: (elem) => `Failed to copy ${elem} to clipboard.`
  },
  processAndTestCreated: {
    de: 'Verfahren und Test erfolgreich angelegt.',
    en: 'Process and test created successfully.'
  },
  participantCreated: {
    de: 'Teilnehmer erfolgreich angelegt.',
    en: 'Participant created successfully.'
  },
  participantAddedSuccessfully: {
    de: 'Teilnehmer erfolgreich hinzugefügt.',
    en: 'Participant added successfully.'
  },
  copyToClipboard: {
    de: 'In die Zwischenablage kopieren',
    en: 'Copy to Clipboard'
  },
  accountDeletionFailed: {
    de: 'Konto konnte nicht gelöscht werden.',
    en: 'Account deletion failed.'
  },
  paypalConnectionFailed: {
    de: 'Verbindung zu PayPal konnte nicht hergestellt werden.',
    en: 'Connection to PayPal could not be established.'
  },
  editingFailed: {
    de: 'Bearbeitung konnte nicht durchgeführt werden.',
    en: 'Editing failed.'
  },
  testAdditionFailed: {
    de: 'Test konnte nicht hinzugefügt werden.',
    en: 'Test addition failed.'
  },
  processAdditionFailed: {
    de: 'Verfahren konnte nicht hinzugefügt werden.',
    en: 'Process addition failed.'
  },
  userAdditionFailed: {
    de: 'Nutzer konnte nicht hinzugefügt werden.',
    en: 'User addition failed.'
  },
  userInvitedByEmail: {
    de: 'Nutzer wurde per E-Mail eingeladen.',
    en: 'User has been invited by email.'
  },
  settingsNotSaved: {
    de: 'Einstellungen konnten nicht gespeichert werden.',
    en: 'Settings could not be saved.'
  },
  notAllDataLoaded: {
    de: 'Es konnten nicht alle Daten geladen werden.',
    en: 'Not all data could be loaded.'
  },
  participantsNotAdded: {
    de: 'Teilnehmer konnten nicht hinzugefügt werden.',
    en: 'Participants could not be added.'
  },
  permissionsNotChanged: {
    de: 'Rechte konnten nicht geändert werden.',
    en: 'Permissions could not be changed.'
  },
  emailsNotSent: {
    de: 'E-Mails konnten nicht versendet werden.',
    en: 'Emails could not be sent.'
  },
  testEmailSent: {
    de: (address) => `Test E-Mail wurde an ${address} versendet.`,
    en: (address) => `Test email has been sent to ${address}.`
  },
  passwordSuccessfullyChanged: {
    de: 'Passwort wurde erfolgreich geändert.',
    en: 'Password was successfully changed.'
  },
  processResultToolTip: {
    de: 'Hier können Sie einstellen, ob und wie die Testergebnisse zu einem Gesamtergebnis verrechnet werden sollen. Das Ergebnis wird erst berechnet, wenn alle gewichteten Tests abgeschlossen wurden.',
    en: 'Here you can configure whether and how the test results should be combined into an overall result. The result will only be calculated once all weighted tests have been completed.'
  },
  unpermittedAidsUsed: {
    de: 'Es wurden unerlaubte Hilfsmittel benutzt',
    en: 'Unpermitted aids were used'
  },
  fileNotFound: {
    de: 'Datei wurde nicht gefunden.',
    en: 'File not found.'
  },
  activateOptionTimeStarts: {
    de: 'Wenn Sie diese Option aktivieren, beginnt die für den Test vorgesehene Zeit zu laufen, sobald die E-Mail versendet wurde.',
    en: 'If you activate this option, the time allocated for the test starts running as soon as the email is sent.'
  },
  startTimerAutomaticallyAfterEmail: {
    de: 'Timer nach E-Mail-Versand automatisch starten?',
    en: 'Start timer automatically after email sent?'
  },
  emailTypeDescription: {
    de: 'Nach Versenden der E-Mail wird der Einladungsstatus automatisch auf „eingeladen” oder „erinnert” gesetzt.',
    en: 'After sending the email, the invitation status will be automatically  set to “invited” or “reminded”.'
  },
  few: {
    de: 'wenig',
    en: 'few'
  },
  frequent: {
    de: 'häufig',
    en: 'frequent'
  },
  tabTrackingInfo: {
    de: (windowChangeCount, windowChangeTime) => (
      <span>
        Die Testumgebung wurde <strong>{windowChangeCount}</strong> mal <br /> für insgesamt{' '}
        <strong>{windowChangeTime}</strong> Sekunden verlassen
      </span>
    ),
    en: (windowChangeCount, windowChangeTime) => (
      <span>
        The test environment was exited <strong>{windowChangeCount}</strong> times for a total of{' '}
        <strong>{windowChangeTime}</strong> seconds.
      </span>
    )
  },
  progress: {
    de: 'Fortschritt',
    en: 'Progress'
  },
  page: {
    de: 'Seite',
    en: 'Page'
  },
  resultPerPage: {
    de: 'Einträge pro Seite',
    en: 'Results per Page'
  },
  duplicate: {
    de: 'Duplizieren',
    en: 'Duplicate'
  },
  confirmDuplicateProcessAndTests: {
    de: 'Sind Sie sicher, dass Sie das gewählte Verfahren und alle zugehörigen Tests duplizieren möchten? Zugeordnete Teilnehmer und Testungen werden nicht dupliziert.',
    en: 'Are you sure you want to duplicate the selected process and all its associated tests? Assigned participants and testings will not be duplicated.'
  },
  startTimer: {
    de: 'Timer starten',
    en: 'Start timer'
  },
  confirmStartTimer: {
    de: 'Sind Sie sicher, dass Sie den Timer für die gewählten Testungen starten möchten?',
    en: 'Are you sure you want to start the timer for the selected tests?'
  },
  resetTimer: {
    de: 'Timer zurücksetzen',
    en: 'Reset timer'
  },
  confirmResetTimer: {
    de: 'Sind Sie sicher, dass Sie den Timer für die gewählten Testungen zurücksetzen möchten?',
    en: 'Are you sure you want to reset the timer for the selected tests?'
  },
  confirmDeleteField: {
    de: (fieldName) => (
      <>
        Sind Sie sicher, dass Sie das Feld <strong>„{fieldName}“</strong> und alle zugehörigen Daten löschen möchten?
        Dieser Schritt kann nicht rückgängig gemacht werden.
      </>
    ),
    en: (fieldName) => (
      <>
        Are you sure you want to delete the field <strong>„{fieldName}“</strong> and all associated data? This action
        cannot be undone.
      </>
    )
  },
  deleteCustomField: {
    de: 'Eigenes Feld löschen',
    en: 'Delete Custom Field'
  },
  deleteField: {
    de: 'Feld löschen',
    en: 'Delete Field'
  },
  german: {
    de: 'Deutsch',
    en: 'German'
  },
  english: {
    de: 'Englisch',
    en: 'English'
  },
  selectReferenceTest: {
    de: 'Bitte wählen Sie einen Referenztest aus',
    en: 'Please select a reference test'
  },
  customerArea: {
    de: 'PERSEO Kundenbereich',
    en: 'PERSEO Customer Area'
  },
  loading: {
    de: 'lade...',
    en: 'loading...'
  },
  permissionsPreview: {
    de: 'Berechtigungen (Vorschau)',
    en: 'Permissions (preview)'
  },
  fieldChangedSuccessfully: {
    de: (fieldName) => (
      <>
        Feld <span style={{ fontWeight: 600 }}>{fieldName}</span> erfolgreich geändert.
      </>
    ),
    en: (fieldName) => (
      <>
        Field <span style={{ fontWeight: 600 }}>{fieldName}</span> successfully changed.
      </>
    )
  },
  sort: {
    de: 'sortieren',
    en: 'sort'
  },
  userSettings: {
    de: 'Nutzereinstellungen',
    en: 'User Settings'
  },
  displayAndLanguage: {
    de: 'Darstellung und Sprache',
    en: 'Display and Language'
  },
  language: {
    de: 'Sprache',
    en: 'Language'
  },
  numberOfPts: {
    de: 'Anzahl Testungen',
    en: 'Number of Testings'
  },
  anonymous: {
    de: 'anonym',
    en: 'anonymous'
  },
  noNameYet: {
    de: 'noch kein Name',
    en: 'no name yet'
  },
  expiredOn: {
    de: 'Abgelaufen am',
    en: 'Expired on'
  },
  availableFrom: {
    de: 'Verfügbar ab',
    en: 'Available from'
  },
  testCannotBeAggregated: {
    de: 'Dieser Test kann nicht verrechnet werden.',
    en: 'This test cannot be used for aggregation.'
  },
  fieldEmpty: {
    de: 'Bitte füllen Sie dieses Feld aus.',
    en: 'Please fill out this field.'
  },
  nameAlreadyInUse: {
    de: 'Dieser Name ist schon in Benutzung.',
    en: 'This name is already in use.'
  },
  nameAlreadyRequested: {
    de: 'Für diesen Namen liegt bereits eine Anfrage vor.',
    en: 'A request for this name already exists.'
  },
  emailAlreadyInUse: {
    de: 'Diese E-Mail-Adresse ist schon in Benutzung.',
    en: 'This email address is already in use.'
  },
  emailAlreadyRequested: {
    de: 'Für diese E-Mail-Adresse liegt bereits eine Anfrage vor.',
    en: 'A request for this email address already exists.'
  },
  emailInvalid: {
    de: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
    en: 'Please enter a valid email address.'
  },
  emailInvalidShort: {
    de: 'E-Mail-Adresse ist ungültig.',
    en: 'Email address is invalid.'
  },
  newPasswordTooShort: {
    de: 'Das neue Passwort ist zu kurz.',
    en: 'The new password is too short.'
  },
  oldPasswordIncorrect: {
    de: 'Das alte Passwort ist falsch.',
    en: 'The old password is incorrect.'
  },
  enterPassword: {
    de: 'Bitte geben Sie Ihr Passwort ein.',
    en: 'Please enter your password.'
  },
  labelIsDuplicate: {
    de: 'Bezeichnung ist doppelt.',
    en: 'Label is duplicate.'
  },
  forbiddenCharacter: {
    de: 'Feld enthält unerlaubte Zeichen.',
    en: 'Field contains invalid characters.'
  },
  testNameCannotBeEmpty: {
    de: 'Testname darf nicht leer sein',
    en: 'Test name cannot be empty'
  },
  nameEmpty: {
    de: 'Der Name darf nicht leer sein',
    en: 'The name cannot be empty'
  },
  enterProcessName: {
    de: 'Bitte geben Sie einen Namen für das Verfahren ein.',
    en: 'Please enter a name for the process.'
  },
  adminOnlyFunction: {
    de: 'Diese Funktion steht nur Administratoren zur Verfügung.',
    en: 'This function is available only to administrators.'
  },
  cannotDeleteAccountDueToNegativeCredits: {
    de: 'Ihr Konto kann nicht gelöscht werden, da Ihr Credit-Guthaben negativ ist. Bitte laden Sie zunächst den ausstehenden Betrag im Credit Shop auf.',
    en: 'Your account cannot be deleted as your credit balance is negative. Please first reload the outstanding amount in the Credit Shop.'
  },
  accessCredentialsLeadToTestOverview: {
    de: 'Diese Zugangsdaten führen zur Testübersicht. Hier können Ihre Teilnehmer alle Tests separat starten.',
    en: 'These access credentials lead to the test overview. Here your participants can start all tests separately.'
  },
  noTestsInHubNotice: {
    de: 'Hier werden keine Zugangsdaten angezeigt, weil keine Tests auf der Testübersicht sichtbar sind. Die Tests können einzeln per Einladungslink oder Login-Code gestartet werden.',
    en: 'No access credentials are shown here because no tests are visible on the test overview. The tests can be started individually using invitation links or login codes.'
  },
  unableToCalculateTotalResult: {
    de: 'Die Tests in diesem Verfahren können nicht zu einem Gesamtergebnis verrechnet werden, weil sie keinen Punktwert als Ergebnis liefern.',
    en: 'The tests in this process cannot be combined into a total result because they do not provide a point value as a result.'
  },
  noTestsInProcess: {
    de: 'Keine Tests im Verfahren',
    en: 'There are no tests in this process'
  },
  noReference: {
    de: 'Keine Referenz',
    en: 'no reference'
  },
  noReferenceTests: {
    de: 'Keine Referenztests vorhanden',
    en: 'No reference tests found'
  },
  noAccessForPreparation: {
    de: () => (
      <>
        Falls Sie Bewerber/in sind und sich auf einen Test vorbereiten möchten, können Sie leider keinen Zugang
        erhalten. Wir bieten keine Übungsmöglichkeiten für unsere Tests an. Sollten Sie Hilfe bei der Klärung benötigen,
        nehmen Sie gerne <SmallExternalLink href="https://perseo.hr/de/kontakt/">Kontakt</SmallExternalLink> mit uns
        auf.
      </>
    ),
    en: () => (
      <>
        If you are an applicant and would like to prepare for a test, unfortunately you cannot gain access. We do not
        provide practice options for our tests. If you need assistance, please feel free to{' '}
        <SmallExternalLink href="https://perseo.hr/de/kontakt/">contact us</SmallExternalLink>.
      </>
    )
  },
  changeCalculationMethod: {
    de: 'Berechnungsmethode ändern',
    en: 'Change calculation method'
  },
  confirmCalculationMethodChange: {
    de: 'Wenn Sie die Berechnungsmethode ändern, werden die Gesamtergebnisse für dieses Verfahren aktualisiert. Sind Sie sicher?',
    en: 'If you change the calculation method, the overall results for this process will be updated. Are you sure?'
  },
  changesSaved: {
    de: 'Änderungen gespeichert.',
    en: 'Changes saved.'
  },
  addButtonExplanation: {
    de: 'Hier können Sie neue Verfahren, Tests und Teilnehmer anlegen.',
    en: 'Here you can create new processes, tests, and participants.'
  },
  dashboardExplanation: {
    de: 'Hier finden Sie die neuesten Testergebnisse, System-Statistiken und können aktuell laufende Tests live verfolgen.',
    en: 'Here you will find the latest test results, system statistics, and the ability to monitor ongoing tests in real-time.'
  },
  processesExplanation: {
    de: 'Verfahren sind Ordner für zusammengehörige Tests. Sie können z.B. ein Verfahren je Stellenausschreibung oder Berufsgruppe erstellen.',
    en: 'Processes are folders for related tests. For example, you can create a process for each job advertisement or professional group.'
  },
  testsExplanation: {
    de: 'Hier finden Sie alle Infos zu Ihren angelegten Tests, wie z.B. Inhalte, Statistiken und Einstellungen.',
    en: 'Here you can access all the information about your created tests, such as content, statistics, and settings.'
  },
  participantsExplanation: {
    de: 'Hier finden Sie alle Infos zu Ihren Teilnehmern, wie persönliche Daten, Zugangsdaten und Ergebnisse.',
    en: 'Here you can access all the information about your participants, including personal data, access credentials, and results.'
  },
  ptsExplanation: {
    de: 'Jeder Teilnehmer kann an einem oder mehreren Tests teilnehmen, was wir „Testung“ nennen. Hier finden Sie u.a. die Zugangsdaten für Ihre Teilnehmer und die Testergebnisse.',
    en: 'Each participant can take one or more tests, which we refer to as „Testings.“ Here you can find the access credentials for your participants and their test results.'
  },
  shopExplanation: {
    de: 'Durchgeführte Tests rechnen wir mit PERSEO Credits ab. Im Shop können Sie Credits für Ihre nächsten Tests aufladen.',
    en: 'We charge PERSEO credits for conducted tests. In the shop, you can purchase credits for your upcoming tests.'
  },
  mainMenuExplanation: {
    de: 'Hier können Sie u.a. Ihre persönlichen Daten ändern, neue Nutzer hinzufügen und Ihre Benachrichtigungen verwalten.',
    en: 'Here you can, among other things, change your personal data, add new users, and manage your notifications.'
  },
  bulkEditExplanation: {
    de: 'Mit den Checkboxen am linken Rand der Tabelle können Sie mehrere Elemente markieren und gleichzeitig bearbeiten.',
    en: 'Using the checkboxes on the left side of the table, you can select multiple items and edit them simultaneously.'
  },
  detailedViewExplanation: {
    de: 'Durch Klick auf den Pfeil oder die gesamte Zeile öffnen Sie die Detailansicht. Dort finden Sie u.a. Zugangsdaten, Einstellungen, Testergebnisse und weitere Infos zum gewählten Eintrag.',
    en: 'By clicking on the arrow or the entire row, you can open the detailed view. There you will find access credentials, settings, test results, and more information about the selected entry.'
  },
  actionMenuExplanation: {
    de: 'Am rechten Rand der Tabelle finden Sie das Aktionsmenü. Dort können Sie z.B. Ergebnisse exportieren oder Tests und Teilnehmer hinzufügen.',
    en: 'On the right side of the table, you will find the action menu. There you can, for example, export results or add tests and participants.'
  },
  bulkEdit: {
    de: 'Stapelverarbeitung',
    en: 'Bulk Edit'
  },
  detailedView: {
    de: 'Detailansicht',
    en: 'Detailed View'
  },
  actionMenu: {
    de: 'Aktionsmenü',
    en: 'Action Menu'
  },
  letsGo: {
    de: `Los geht's!`,
    en: `Let's go!`
  },
  thankYouForRequest: {
    de: (email) => (
      <>
        Vielen Dank für Ihre Anfrage. Wir haben eine E-Mail an <span style={{ fontWeight: 600 }}>{email}</span>{' '}
        geschickt und werden Ihre Anfrage so schnell wie möglich bearbeiten.
      </>
    ),
    en: (email) => (
      <>
        Thank you for your request. We have sent an email to <span style={{ fontWeight: 600 }}>{email}</span> and will
        process your inquiry as soon as possible.
      </>
    )
  },
  accessRequested: {
    de: 'Zugang angefordert',
    en: 'Access Requested'
  },
  getStarted: {
    de: 'Sofort loslegen',
    en: 'Get started now'
  },
  introduction: {
    de: 'Einführung',
    en: 'Introduction'
  },
  startIntroduction: {
    de: 'Einführung starten',
    en: 'Start Introduction'
  },
  timerStarted: {
    de: 'Timer gestartet',
    en: 'Timer started'
  },
  timerNotStarted: {
    de: 'Timer nicht gestartet',
    en: 'Timer not started'
  },
  testStarted: {
    de: 'Test gestartet',
    en: 'Test started'
  },
  testFinished: {
    de: 'Test abgeschlossen',
    en: 'Test finished'
  },
  invitationSent: {
    de: 'Einladung gesendet',
    en: 'Invitation sent'
  },
  reminderSent: {
    de: 'Erinnerung gesendet',
    en: 'Reminder sent'
  },
  ptUsed: {
    de: 'Genutzte TAN',
    en: 'Used TAN'
  },
  ptReset: {
    de: 'TAN Reset',
    en: 'TAN Reset'
  },
  creditChargePerseo: {
    de: 'Gutschrift',
    en: 'Credit Charge'
  },
  creditChargeContact: {
    de: 'Aufladung',
    en: 'Credit Top-Up'
  },
  creditChargeMonthly: {
    de: 'Guthaben-Ausgleich',
    en: 'Credit Balance Adjustment'
  },
  miscBooking: {
    de: 'Sonstige Buchung',
    en: 'Other Booking'
  },
  applySettingsToTestings: {
    de: 'Einstellung auf Testungen übertragen',
    en: 'Apply Settings to Testings'
  },
  confirmChanges: {
    de: (selectRowCount, entityLabel) => `Änderungen für ${selectRowCount} ${entityLabel} bestätigen`,
    en: (selectRowCount, entityLabel) => `Confirm Changes for ${selectRowCount} ${entityLabel}`
  },
  applyChangesToPts: {
    de: 'Übertragen',
    en: 'Apply'
  },
  doNotApplyChangesToPts: {
    de: 'Nicht übertragen',
    en: `Don't apply`
  },
  copyToPtExplanation: {
    de: (numberPtLabel) => (
      <>
        Möchten Sie diese Einstellung auf {numberPtLabel} zugehörige Testungen übertragen? Falls nein, gilt diese
        Einstellung nur für neu angelegte Testungen.
      </>
    ),
    en: (numberPtLabel) => (
      <>
        Would you like to apply this setting to {numberPtLabel} associated testings? If not, this setting will only
        apply to newly created testings.
      </>
    )
  },
  of: {
    de: 'von',
    en: 'out of'
  },
  hide: {
    de: 'Verbergen',
    en: 'Hide'
  },
  editDotDot: {
    de: 'Bearbeiten...',
    en: 'Edit...'
  },
  alertModalHeadline: {
    de: (countLabel, entityLabel, actionLabel) => `${countLabel} ${entityLabel} ${lowerCaseFirstLetter(actionLabel)}?`,
    en: (countLabel, entityLabel, actionLabel) => `${actionLabel} ${countLabel} ${entityLabel}?`
  },
  theSelectedTests: {
    de: 'die gewählten Tests',
    en: 'the selected Tests'
  },
  theSelectedTest: {
    de: 'den gewählten Test',
    en: 'the selected Test'
  },
  theSelectedMail: {
    de: 'die gewählte E-Mail',
    en: 'the selected email'
  },
  theSelectedMails: {
    de: 'die gewählten E-Mails',
    en: 'the selected emails'
  },
  theSelectedParticipants: {
    de: 'die gewählten Teilnehmer',
    en: 'the selected participants'
  },
  theSelectedParticipant: {
    de: 'den gewählten Teilnehmer',
    en: 'the selected participant'
  },
  theSelectedProcesses: {
    de: 'die gewählten Verfahren',
    en: 'the selected processes'
  },
  theSelectedProcess: {
    de: 'das gewählte Verfahren',
    en: 'the selected process'
  },
  theSelectedPts: {
    de: 'die gewählten Testungen',
    en: 'the selected testings'
  },
  theSelectedPt: {
    de: 'die gewählte Testung',
    en: 'the selected testing'
  },
  theSelectedReminder: {
    de: 'die gewählte Erinnerung',
    en: 'the selected reminder'
  },
  confirmEdit: {
    de: (entity, action) => `Sind Sie sicher, dass Sie ${entity} ${lowerCaseFirstLetter(action)} möchten?`,
    en: (entity, action) => `Are you sure you want to ${lowerCaseFirstLetter(action)} ${entity}?`
  },
  confirmTestEdit: {
    de: (entity, action) =>
      `Sind Sie sicher, dass Sie ${entity}, zugeordnete Teilnehmer und zugeordnete Testungen ${lowerCaseFirstLetter(
        action
      )} möchten?`,
    en: (entity, action) =>
      `Are you sure you want to ${lowerCaseFirstLetter(action)} ${entity} as well as related participants and testings?`
  },
  confirmParticipantEdit: {
    de: (entity, action) =>
      `Sind Sie sicher, dass Sie ${entity} und alle zugeordnete Testungen ${lowerCaseFirstLetter(action)} möchten?`,
    en: (entity, action) =>
      `Are you sure you want to ${lowerCaseFirstLetter(action)} ${entity} as well as all related testings?`
  },
  packetPrice: {
    de: 'Paketpreise',
    en: 'Packet Price'
  },
  pricePerCredit: {
    de: 'Preis / Credit',
    en: 'Price / Credit'
  },
  uponRequest: {
    de: 'auf Anfrage',
    en: 'upon request'
  },
  germany: {
    de: 'Deutschland',
    en: 'Germany'
  },
  imprintTitle: {
    de: 'Angaben gemäß § 5 DDG',
    en: 'Information according to § 5 DSA'
  },
  representativeCEO: {
    de: 'Vertretungsberechtiger Geschäftsführer',
    en: 'Authorized Managing Director'
  },
  contact: {
    de: 'Kontakt',
    en: 'Contact'
  },
  registerEntry: {
    de: 'Registereintrag',
    en: 'Register Entry'
  },
  court: {
    de: 'Registergericht',
    en: 'Court'
  },
  registerNumber: {
    de: 'Registernummer',
    en: 'Registration Number'
  },
  vatID: {
    de: 'Umsatzsteuer-Identifikationsnummer',
    en: 'VAT ID Number'
  },
  vatIDLong: {
    de: 'Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz',
    en: 'VAT ID Number according to §27a Value Added Tax Act'
  },
  responsibleContent: {
    de: 'Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV',
    en: 'Responsible for Content according to § 55 para 2 RStV'
  },
  representedBy: {
    de: 'Vertreten durch',
    en: 'Represented by'
  },
  download: {
    de: 'downloaden',
    en: 'download'
  },
  downloadFeedback: {
    de: 'Feedback downloaden',
    en: 'Download feedback'
  },
  customTestsOnly: {
    de: 'Eigene Tests',
    en: 'Custom Tests'
  },
  errorOccured: {
    de: 'Ein Fehler ist aufgetreten.',
    en: 'An error occurred.'
  },
  dataLoadError: {
    de: 'Daten können nicht geladen werden.',
    en: 'Data cannot be loaded.'
  },
  archiveLoading: {
    de: 'Archiv wird geladen...',
    en: 'Archive is loading...'
  },
  connectionError: {
    de: 'Verbindungsfehler',
    en: 'Connection error'
  },
  endTimeBeforeStartTime: {
    de: 'Endzeitpunkt darf nicht vor Startzeitpunkt liegen.',
    en: 'The end time cannot be before the start time.'
  },
  dhmString: {
    de: (d, h, m) => {
      return (
        `${d === 1 ? d + ' Tag ' : d > 1 ? d + ' Tage ' : ''}` +
        `${h > 0 ? h + ' Stunden ' : ''}` +
        `${m > 0 ? m + ' Minuten ' : ''}`
      )
    },
    en: (d, h, m) => {
      return (
        `${d === 1 ? d + ' Day ' : d > 1 ? d + ' Days ' : ''}` +
        `${h > 0 ? h + ' Hours ' : ''}` +
        `${m > 0 ? m + ' Minutes ' : ''}`
      )
    }
  },
  customTest: {
    de: 'individueller Test',
    en: 'Custom Test'
  },
  languageSelectExplanation: {
    de: 'Die hier gewählte Sprache betrifft nur den Kundenbereich. Die Sprache der Tests können Sie in den Testoptionen einstellen.',
    en: 'The language selected here only affects the customer area. You can set the language of the tests in the test options.'
  },
  lightMode: {
    de: 'Hell',
    en: 'Light'
  },
  auto: {
    de: 'Automatisch',
    en: 'Automatic'
  },
  darkMode: {
    de: 'Dunkel',
    en: 'Dark'
  },
  displayMode: {
    de: 'Anzeigemodus',
    en: 'Display Mode'
  },
  viewProcess: {
    de: 'Verfahren ansehen',
    en: 'Go to Process'
  },
  togglePasswordVisibility: {
    de: 'Passwort Sichtbarkeit umschalten',
    en: 'Toggle Password Visibility'
  },
  performanceTest: {
    de: 'Leistungstest',
    en: 'Performance Test'
  },
  personalityTest: {
    de: 'Persönlichkeitstest',
    en: 'Personality Test'
  },
  interestTest: {
    de: 'Interessentest',
    en: 'Interest Test'
  },
  lateralLeadership: {
    de: 'Laterale Führung',
    en: 'Lateral Leadership'
  },
  crosstest: {
    de: 'Crosstest',
    en: 'Cross Test'
  },
  aptitudeTest: {
    de: 'Eignungstest',
    en: 'Aptitude Test'
  },
  performanceProfile: {
    de: 'Leistungsprofil',
    en: 'Performance Profile'
  },
  personalityProfile: {
    de: 'Persönlichkeitsprofil',
    en: 'Personality Profile'
  },
  interestProfile: {
    de: 'Interessenprofil',
    en: 'Interest Profile'
  },
  confirmDeleteOwnAccount: {
    de: 'Sind Sie sicher, dass Sie Ihren eigenen Nutzer-Account löschen möchten?',
    en: 'Are you sure you want to delete your own user account?'
  },
  confirmDeleteAccount: {
    de: (contact) => `Sind Sie sicher, dass Sie den Nutzer ${contact} löschen möchten?`,
    en: (contact) => `Are you sure you want to delete the user ${contact}?`
  },
  targetProfile: {
    de: 'Soll-Profil',
    en: 'Target Profile'
  },
  currentProfile: {
    de: 'Aktuelles Profil',
    en: 'Current Profile'
  },
  with: {
    de: 'mit',
    en: 'with'
  },
  germanFormal: {
    de: 'Deutsch (Sie)',
    en: 'German (formal)'
  },
  turkish: {
    de: 'Türkisch',
    en: 'Turkish'
  },
  arabic: {
    de: 'Arabisch',
    en: 'Arabic'
  },
  french: {
    de: 'Französisch',
    en: 'French'
  },
  germanInformal: {
    de: 'Deutsch (Du)',
    en: 'German (informal)'
  },
  availableLanguages: {
    de: 'Verfügbare Sprachen',
    en: 'Available Languages'
  },
  viewTest: {
    de: 'Test ansehen',
    en: 'Go to Test'
  },
  priceList: {
    de: 'Preisliste',
    en: 'Price List'
  },
  customerAreaManual: {
    de: 'Kundenbereich Manual',
    en: 'Customer Area Manual'
  },
  interpretationNotesFor: {
    de: 'Interpretationshinweise für',
    en: 'Interpretation Notes for'
  },
  orderProcessingContract: {
    de: 'Vertrag zur Auftragsverarbeitung',
    en: 'Order Processing Contract'
  },
  bitvTestReport: {
    de: 'Prüfbericht BITV-Test',
    en: 'BITV Test Report'
  },
  hetzner27001Certificate: {
    de: 'DIN 27001 Zertifikat Hetzner',
    en: 'Hetzner 27001 Certificate'
  },
  customerAreaManualNote: {
    de: 'Anleitung für den PERSEO Kundenbereich',
    en: 'PERSEO Customer Area Guide'
  },
  performanceTestResultNotes: {
    de: 'Erklärungen zum Ergebnisreport unserer Leistungstests',
    en: 'Explanations for the Result Reports of our Performance Tests'
  },
  sampleContractWithTOMs: {
    de: 'Mustervertrag inkl. TOMs und Verzeichnis von Verarbeitungstätigkeiten',
    en: 'Sample Contract with TOMs and Record of Processing Activities'
  },
  accessibilityProof: {
    de: 'Nachweis der Barrierefreiheit gem. BITV / EN 301 549',
    en: 'Accessibility Proof according to BITV / EN 301 549'
  },
  hetzner27001Proof: {
    de: 'Nachweis ISO/IEC 27001:2013 unseres Hosting-Partners Hetzner Online',
    en: 'Proof of ISO/IEC 27001:2013 by our Hosting Partner Hetzner Online'
  },
  referenceTest: {
    de: 'Referenztest',
    en: 'Reference Test'
  },
  reference: {
    de: 'Referenz',
    en: 'Reference'
  },
  testPrice: {
    de: 'Testpreis',
    en: 'Test Price'
  },
  testDesign: {
    de: 'Test-Design',
    en: 'Test Design'
  },
  setNewPassword: {
    de: 'Neues Passwort festlegen',
    en: 'Set New Password'
  },
  sentMailTo: {
    de: (email) => (
      <>
        Falls ein passendes Konto gefunden wurde, haben wir eine E-Mail an <strong>{email}</strong> geschickt, mit der
        Sie Ihr Passwort zurücksetzen können.
      </>
    ),
    en: (email) => (
      <>
        If a matching account was found an email was sent to <strong>{email}</strong> to allow you to reset your
        password.
      </>
    )
  },
  shorttest: {
    de: 'Kurztest',
    en: 'Short Test'
  },
  emailTemplates: {
    de: 'E-Mail-Vorlagen',
    en: 'Email Templates'
  },
  template: {
    de: 'Vorlage',
    en: 'Template'
  },
  templateSavedSuccessfully: {
    de: 'Vorlage erfolgreich gespeichert.',
    en: 'Template successfully saved.'
  },
  testlink: {
    de: 'Testlink',
    en: 'Test Link'
  },
  freeTextFilter: {
    de: 'Freitext-Filter',
    en: 'Free Text Filter'
  },
  is: {
    de: 'ist',
    en: 'is'
  },
  isNot: {
    de: 'ist nicht',
    en: 'is not'
  },
  contains: {
    de: 'enthält',
    en: 'contains'
  },
  containsNot: {
    de: 'enthält nicht',
    en: 'does not contain'
  },
  relation: {
    de: 'Beziehung',
    en: 'Relation'
  },
  ptCount: {
    de: 'Anzahl Testungen',
    en: 'Number Testings'
  },
  participantCount: {
    de: 'Anzahl Teilnehmer',
    en: 'Number Participants'
  },
  activeFilter: {
    de: 'Aktiver Filter',
    en: 'Active Filter'
  },
  active: {
    de: 'aktiv',
    en: 'active'
  },
  inactive: {
    de: 'inaktiv',
    en: 'inactive'
  },
  timings: {
    de: 'Zeiten',
    en: 'Timings'
  },
  processName: {
    de: 'Verfahrensname',
    en: 'Process Name'
  },
  greaterThan: {
    de: 'ist größer als',
    en: 'is greater than'
  },
  greaterThanOrEqualTo: {
    de: 'ist größer oder gleich',
    en: 'is greater than or equal to'
  },
  lessThan: {
    de: 'ist kleiner als',
    en: 'is less than'
  },
  lessThanOrEqualTo: {
    de: 'ist kleiner oder gleich',
    en: 'is less than or equal to'
  },
  yesterday: {
    de: 'gestern',
    en: 'yesterday'
  },
  tomorrow: {
    de: 'morgen',
    en: 'tomorrow'
  },
  lastWeek: {
    de: 'letzte Woche',
    en: 'last week'
  },
  nextWeek: {
    de: 'nächste Woche',
    en: 'next week'
  },
  lastMonth: {
    de: 'letzten Monat',
    en: 'last month'
  },
  nextMonth: {
    de: 'nächsten Monat',
    en: 'next month'
  },
  lastYear: {
    de: 'letztes Jahr',
    en: 'last year'
  },
  nextYear: {
    de: 'nächstes Jahr',
    en: 'next year'
  },
  relativeDate: {
    de: 'Relative Zeit',
    en: 'Relative Dates'
  },
  specificDate: {
    de: 'Genaue Zeit',
    en: 'Specific Date'
  },
  isLaterThan: {
    de: 'ist später als',
    en: 'is later than'
  },
  isBefore: {
    de: 'ist früher als',
    en: 'is before'
  },
  dateInput: {
    de: 'Datum',
    en: 'Date'
  },
  relative: {
    de: 'Relativ',
    en: 'Relative'
  },
  isEmpty: {
    de: 'ist leer',
    en: 'is empty'
  },
  isNotEmpty: {
    de: 'ist nicht leer',
    en: 'is not empty'
  },
  isBetween: {
    de: 'ist zwischen',
    en: 'is between'
  },
  and: {
    de: 'und',
    en: 'and'
  },
  dragAndDropLines: {
    de: 'Zeilen verschieben, um die Reihenfolge zu ändern',
    en: 'Drag and drop rows to change the order'
  },
  testingStatus: {
    de: 'Testungsstatus',
    en: 'Testing Status'
  },
  addFilter: {
    de: 'Filter hinzufügen',
    en: 'Add Filter'
  },
  editFilter: {
    de: 'Filter bearbeiten',
    en: 'Edit Filter'
  },
  copyLink: {
    de: 'Testlink kopieren',
    en: 'Copy test link'
  },
  link: {
    de: 'Link',
    en: 'Link'
  },
  passwordRecoveryFailed: {
    de: 'Passwort konnte nicht geändert werden. Bitte starten Sie den Prozess erneut oder wenden sich an den PERSEO Support.',
    en: 'Password could not be changed. Please restart the process or contact the PERSEO support.'
  },
  invoices: {
    de: 'Rechnungen',
    en: 'Invoices'
  },
  net: {
    de: 'Netto',
    en: 'Net'
  },
  gross: {
    de: 'Brutto',
    en: 'Gross'
  },
  paymentMethod: {
    de: 'Zahlungsart',
    en: 'Payment Method'
  },
  invoiceNo: {
    de: 'Rechnungsnummer',
    en: 'Invoice Number'
  },
  status: {
    de: 'Status',
    en: 'Status'
  },
  dateBilled: {
    de: 'Rechnungsdatum',
    en: 'Invoice Date'
  },
  datePaid: {
    de: 'Zahlungseingang',
    en: 'Payment received'
  },
  creditCardData: {
    de: 'Kreditkarten-Daten',
    en: 'Credit Card Data'
  },
  draft: {
    de: 'Entwurf',
    en: 'Draft'
  },
  sent: {
    de: 'Ausstehend',
    en: 'Pending'
  },
  paid: {
    de: 'Bezahlt',
    en: 'Paid'
  },
  overdue: {
    de: 'Überfällig',
    en: 'Overdue'
  },
  canceled: {
    de: 'Abgebrochen',
    en: 'Canceled'
  },
  refunded: {
    de: 'Erstattet',
    en: 'Refunded'
  },
  recipient: {
    de: 'Empfänger',
    en: 'Recipient'
  },
  recipientCc: {
    de: 'Empfänger CC',
    en: 'Recipient CC'
  },
  entry: {
    de: 'Eintrag',
    en: 'Entry'
  },
  entries: {
    de: 'Einträge',
    en: 'Entries'
  },
  netDays: {
    de: 'Zahlungsziel',
    en: 'Net Days'
  },
  reportCreationInProgress: {
    de: 'Reports werden erstellt…',
    en: 'Reports are being created…'
  },
  reportsCreated: {
    de: 'Reports erfolgreich erstellt.',
    en: 'Reports successfully created.'
  },
  reportCreationError: {
    de: 'Reports konnten nicht erstellt werden.',
    en: 'Reports could not be created.'
  },
  testSecurity: {
    de: 'Testsicherheit',
    en: 'Test Security'
  },
  tabTracking: {
    de: 'Fensterwechsel überwachen',
    en: 'Track Window Switches'
  },
  cameraSupervision: {
    de: 'Kamera-Überwachung',
    en: 'Camera Supervision'
  },
  on: {
    de: 'ein',
    en: 'on'
  },
  off: {
    de: 'aus',
    en: 'off'
  },
  withIdCheck: {
    de: 'mit Identitätsnachweis',
    en: 'with ID Check'
  },
  withoutIdCheck: {
    de: 'ohne Identitätsnachweis',
    en: 'without ID Check'
  },
  moreInfos: {
    de: 'Weitere Informationen',
    en: 'More Infos'
  },
  tabTrackingExplanation: {
    de: 'Mit dieser Funktion erfassen Sie, wann und wie oft Ihre Kandidaten während des Tests das Browserfenster wechseln.',
    en: 'This feature allows you to track when and how often your candidates switch browser windows during the test.'
  },
  cameraSupervisionExplanation: {
    de: 'Um Täuschungsversuche zu reduzieren, können Sie den Test bei laufender Kamera durchführen lassen. Dabei handelt es sich um eine Abschreckungsmaßnahme ohne Übertragung von Daten.',
    en: 'To reduce attempts at cheating, you can have the test conducted with the camera running. This acts as a deterrent without transmitting any data.'
  },
  requiredMaterials: {
    de: 'Benötigte Materialien',
    en: 'Required Materials'
  },
  penAndPaper: {
    de: 'Papier und Stift',
    en: 'Pen and Paper'
  },
  camera: {
    de: 'Kamera',
    en: 'Webcam'
  },
  photoId: {
    de: 'Lichtbildausweis',
    en: 'Photo ID'
  },
  performanceTestsOnly: {
    de: 'Diese Option steht nur bei Leistungstests zur Verfügung.',
    en: 'This option is only available for performance tests.'
  },
  loadingArchive: {
    de: 'Lade archivierte Elemente…',
    en: 'Loading archived elements…'
  },
  archiveLoaded: {
    de: 'Archivierte Elemente geladen.',
    en: 'Archived elements loaded.'
  },
  archiveIsEmpty: {
    de: 'Das Archiv ist leer.',
    en: 'The archive is empty.'
  },
  dataMaintenance: {
    de: 'Datenpflege',
    en: 'Data Maintenance'
  },
  archiveExplanation: {
    de: 'Für eine bessere Übersicht und schnellere Ladezeiten können Sie alte Verfahren und zugehörige Daten automatisch archivieren. Archivierte Daten sind jederzeit auf Abruf verfügbar.',
    en: 'For better clarity and faster loading times, you can automatically archive old processes and associated data. Archived data is available on demand at any time.'
  },
  archiveDeadline: {
    de: 'Frist zur Archivierung',
    en: 'Archiving Deadline'
  },
  archived: {
    de: 'Archiviert',
    en: 'Archived'
  },
  table: {
    de: 'Tabelle',
    en: 'Table'
  },
  errorHeadline: {
    de: 'Ein Fehler ist aufgetreten',
    en: 'An error has occurred'
  },
  errorBody: {
    de: 'Bitte laden Sie die Seite neu. Sollte das Problem fortbestehen, wenden Sie sich bitte an unseren Support unter support@perseo.hr. Wir bitten, den Fehler zu entschuldigen.',
    en: 'Please reload the page. If the problem persists, please contact our support at support@perseo.hr. We apologize for the error.'
  },
  search: {
    de: 'Suchen',
    en: 'Search'
  },
  rank: {
    de: 'Rang',
    en: 'Rank'
  },
  scoring: {
    de: 'Auswertungsregeln',
    en: 'Scoring Rules'
  },
  saveAs: {
    de: 'Speichern unter',
    en: 'Save as'
  },
  rename: {
    de: 'Umbenennen',
    en: 'Rename'
  },
  rule: {
    de: 'Regel',
    en: 'Rule'
  },
  newRule: {
    de: 'Neue Regel',
    en: 'New Rule'
  },
  from: {
    de: 'Von',
    en: 'From'
  },
  upTo: {
    de: 'Bis',
    en: 'To'
  },
  editorDeleteWarning: {
    de: (numberRelatedTests, actionType, elementType) => {
      if (elementType === 'norms') {
        return (
          <>
            Diese Benotungsregel wird aktuell von {numberRelatedTests} Test(s) genutzt. Wenn Sie sie{' '}
            {actionType === 'delete' ? 'löschen' : 'überschreiben'}, werden die Ergebnisse mit der voreingestellten
            Benotungsregel neu berechnet. Sind Sie sicher?
          </>
        )
      }
      if (elementType === 'screens') {
        return (
          <>
            Diese Seite wird aktuell von {numberRelatedTests} Test(s) genutzt. Sind Sie sicher, dass Sie sie{' '}
            {actionType === 'delete' ? 'löschen' : 'überschreiben'} möchten?
          </>
        )
      }
    },
    en: (numberRelatedTests, actionType, elementType) => {
      if (elementType === 'norms') {
        return (
          <>
            This grading rule is currently being used by {numberRelatedTests} test(s). If you{' '}
            {actionType === 'delete' ? 'delete' : 'overwrite'} it, results will be recalculated with the default grading
            rule. Are you sure?
          </>
        )
      }
      if (elementType === 'screens') {
        return (
          <>
            This screen rule is currently being used by {numberRelatedTests} test(s). Are you sure you want to
            {actionType === 'delete' ? 'delete' : 'overwrite'} it?
          </>
        )
      }
    }
  },
  overwrite: {
    de: 'Überschreiben',
    en: 'Overwrite'
  },
  description: {
    de: 'Beschreibung',
    en: 'Description'
  },
  onDate: {
    de: 'am',
    en: 'on'
  },
  standardTemplate: {
    de: 'Vorlage Leistungstests',
    en: 'Standard Template'
  },
  standardTemplateTraits: {
    de: 'Vorlage Job-Matching',
    en: 'Job Matching Template'
  },
  by: {
    de: 'von',
    en: 'by'
  },
  created: {
    de: 'Erstellt',
    en: 'Created'
  },
  weightSaveHint: {
    de: 'Änderungen werden als eigenes Schema gespeichert und dem Test zugewiesen. Pro Test kann nur ein Schema gespeichert werden.',
    en: 'Changes are saved as a custom scheme and applied to the test. Only one scheme can be saved per test.'
  },
  profileSaveHint: {
    de: 'Änderungen werden als eigenes Profil gespeichert und dem Test zugewiesen. Pro Test kann nur ein Profil gespeichert werden.',
    en: 'Changes are saved as a custom profile and applied to the test. Only one profile can be saved per test.'
  },
  editProfile: {
    de: 'Profil bearbeiten',
    en: 'Edit Profile'
  },
  editScheme: {
    de: 'Gewichtung bearbeiten',
    en: 'Edit Weights'
  },
  profile: {
    de: 'Profil',
    en: 'Profile'
  },
  deleteRow: {
    de: 'Zeile löschen',
    en: 'Delete row'
  },
  saveNewSchemeFirst: {
    de: 'Bitte speichern Sie zunächst das neue Element.',
    en: 'Please save the new element before adding another.'
  },
  weightingScheme: {
    de: 'Gewichtungsschema',
    en: 'Weighting Scheme'
  },
  scoreLimits: {
    de: 'Punktgrenzen',
    en: 'Score Limits'
  },
  gradingRule: {
    de: 'Benotungsregel',
    en: 'Grading Rule'
  },
  grading: {
    de: 'Benotung',
    en: 'Grading'
  },
  gradingRules: {
    de: 'Benotungsregeln',
    en: 'Grading Rules'
  },
  ruleSavedSuccessfully: {
    de: 'Regel erfolgreich gespeichert.',
    en: 'Rule saved successfully.'
  },
  screenSavedSuccessfully: {
    de: 'Seite erfolgreich gespeichert.',
    en: 'Screen saved successfully.'
  },
  reminderSavedSuccessfully: {
    de: 'Erinnerung erfolgreich gespeichert.',
    en: 'Reminder saved successfully.'
  },
  customScheme: {
    de: 'Eigenes Schema',
    en: 'Custom Scheme'
  },
  customProfile: {
    de: 'Eigenes Profil',
    en: 'Custom Profile'
  },
  ruleSaveError: {
    de: 'Element konnte nicht gespeichert werden',
    en: 'Element could not be saved'
  },
  updatingResults: {
    de: (progress) => <span>Ergebnisse werden aktualisiert… {parseInt(progress * 100)} %</span>,
    en: (progress) => <span>Results are being updated… {parseInt(progress * 100)} %</span>
  },
  changeGradingInSettings: {
    de: (onClick) => (
      <>
        Benotungsregeln können in den{' '}
        <button className="textlink" onClick={onClick}>
          globalen Einstellungen
        </button>{' '}
        bearbeitet werden.
      </>
    ),
    en: (onClick) => (
      <>
        Grading rules can be edited in the{' '}
        <button className="textlink" onClick={onClick}>
          global settings
        </button>{' '}
        section.
      </>
    )
  },
  customization: {
    de: 'Personalisierung',
    en: 'Customization'
  },
  customScreens: {
    de: 'Start- und Abschlussseiten',
    en: 'Start and End Screens'
  },
  screen: {
    de: 'Seite',
    en: 'Screen'
  },
  screens: {
    de: 'Start- und Abschlussseiten',
    en: 'Start and End Screens'
  },
  startScreenDefault: {
    de: 'Vorlage Startseite',
    en: 'Start Screen Template'
  },
  endScreenDefault: {
    de: 'Vorlage Abschlussseite',
    en: 'End Screen Template'
  },
  screenType: {
    de: 'Seiten-Typ',
    en: 'Screen Type'
  },
  headline: {
    de: 'Überschrift',
    en: 'Headline'
  },
  newScreen: {
    de: 'Neue Seite',
    en: 'New Screen'
  },
  newStartScreen: {
    de: 'Neue Startseite',
    en: 'New Start Screen'
  },
  newEndScreen: {
    de: 'Neue Abschlussseite',
    en: 'New End Screen'
  },
  startScreen: {
    de: 'Startseite',
    en: 'Start Screen'
  },
  endScreen: {
    de: 'Abschlussseite',
    en: 'End Screen'
  },
  changeScreensInSettings: {
    de: (onClick) => (
      <>
        Start- und Abschlussseiten können in den{' '}
        <button className="textlink" onClick={onClick}>
          globalen Einstellungen
        </button>{' '}
        bearbeitet werden.
      </>
    ),
    en: (onClick) => (
      <>
        Start and end screens can be edited in the{' '}
        <button className="textlink" onClick={onClick}>
          global settings
        </button>{' '}
        section.
      </>
    )
  },
  automaticReminders: {
    de: 'Automatische Erinnerungen',
    en: 'Automatic Reminders'
  },
  emailSettings: {
    de: 'E-Mail-Einstellungen',
    en: 'Email Settings'
  },
  after_invitation: {
    de: 'nach Einladung',
    en: 'after invitation'
  },
  before_valid_until: {
    de: 'vor Ablauf',
    en: 'before expiration'
  },
  editReminder: {
    de: 'Erinnerung bearbeiten',
    en: 'Edit Reminder'
  },
  selectTests: {
    de: 'Tests wählen',
    en: 'Select tests'
  },
  testsForReminderExplanation: {
    de: 'Für welche Tests soll diese Erinnerung verschickt werden?',
    en: 'For which tests should this reminder be sent?'
  },
  sendTime: {
    de: 'Versandzeitpunkt',
    en: 'Send Time'
  },
  sendTimeDescription: {
    de: 'Hier können Sie festlegen, wann die Erinnerungen verschickt werden sollen.',
    en: 'Here you can set when the reminders should be sent.'
  },
  selectedTests: {
    de: 'Gewählte Tests',
    en: 'Selected Tests'
  },
  reminders: {
    de: 'Erinnerungen',
    en: 'Reminders'
  },
  activateReminder: {
    de: 'Automatischen Versand aktivieren',
    en: 'Activate automatic send'
  },
  dontActivate: {
    de: 'Nicht aktivieren',
    en: "Don't activate"
  },
  activateLaterInfo: {
    de: 'Sie können den automatischen Versand jederzeit aktivieren und deaktiveren.',
    en: 'You can activate and deactivate automatic send at any time.'
  },
  reminderSuccessfullyActivated: {
    de: 'Erinnerung erfolgreich aktiviert.',
    en: 'Reminder successfully activated.'
  },
  reminderSuccessfullyDeactivated: {
    de: 'Erinnerung erfolgreich deaktiviert.',
    en: 'Reminder successfully deactivated.'
  },
  reminderSuccessfullyDuplicated: {
    de: 'Erinnerung erfolgreich dupliziert.',
    en: 'Reminder successfully duplicated.'
  },
  subjectAndContent: {
    de: 'E-Mail gestalten',
    en: 'Subject and Content'
  },
  reminderTitle: {
    de: 'Titel der Erinnerung',
    en: 'Reminder Title'
  },
  newReminder: {
    de: 'Neue Erinnerung',
    en: 'New reminder'
  },
  onlyVisibleForYou: {
    de: 'Nur für Sie sichtbar.',
    en: 'Only visible for you.'
  },
  more: {
    de: 'weitere',
    en: 'more'
  },
  sendAgain: {
    de: 'Erneut senden',
    en: 'Send again'
  },
  sentBy: {
    de: 'Versendet von',
    en: 'Sent by'
  },
  resendMailConfirmation: {
    de: (type, mail_address) => (
      <span>
        {type === 'invitation' ? 'Einladung' : 'Erinnerung'} erneut an{' '}
        <span style={{ fontWeight: 600 }}>{mail_address}</span> senden?
      </span>
    ),
    en: (type, mail_address) => (
      <span>
        Send the {type === 'invitation' ? 'invitation' : 'reminder'} to{' '}
        <span style={{ fontWeight: 600 }}>{mail_address}</span> again?
      </span>
    )
  },
  showPreview: {
    de: 'Vorschau anzeigen',
    en: 'Show preview'
  },
  participantSelect: {
    de: 'Teilnehmer wählen',
    en: 'Select participants'
  },
  selectParticipantForMail: {
    de: 'Bitte wählen Sie, welche Teilnehmer eine E-Mail erhalten sollen.',
    en: 'Please select which participants should receive an email.'
  },
  lastMailDate: {
    de: 'Letzte E-Mail',
    en: 'Last email'
  },
  duplicateReminder: {
    de: 'Erinnerung duplizieren',
    en: 'Duplicate Reminder'
  },
  confirmDuplicateReminder: {
    de: 'Sind Sie sicher, dass Sie die gewählte Erinnerung duplizieren möchten?',
    en: 'Are you sure you want to duplicate the selected reminder?'
  },
  participantSelectForEmailHint: {
    de: () => (
      <>
        Es stehen nur Teilnehmer mit E-Mail-Adresse zur Auswahl. E-Mail-Adressen können unter{' '}
        <em>Teilnehmer {'>'} Einstellungen</em> oder über die Funktion <em>Teilnehmerliste hochladen</em> hinzugefügt
        werden.
      </>
    ),
    en: () => (
      <>
        Only participants with an email address are available for selection. Email addresses can be added under{' '}
        <em>Participants {'>'} Settings</em> or using the <em>Upload Participant List</em> function.
      </>
    )
  },
  saveAsTemplate: {
    de: 'Vorlage speichern...',
    en: 'Save template...'
  },
  newTemplate: {
    de: 'Neue Vorlage',
    en: 'New Template'
  },
  noSalutation: {
    de: 'keine Anrede',
    en: 'no salutation'
  },
  previewAndTestMail: {
    de: 'Vorschau und Muster-E-Mail',
    en: 'Preview and Test Mail'
  },
  fileUploadHint: {
    de: 'Erlaubte Dateitypen: .png / .jpg (max. 2 MB)',
    en: 'Allowed file types: .png / .jpg (max. 2 MB)'
  },
  uploadLogo: {
    de: 'Logo hochladen',
    en: 'Upload logo'
  },
  mediaLibrary: {
    de: 'Mediathek',
    en: 'Media Library'
  },
  uploadFailed: {
    de: 'Upload fehlgeschlagen',
    en: 'Upload failed'
  },
  headerColor: {
    de: 'Header Farbe',
    en: 'Header Color'
  },
  footerColor: {
    de: 'Footer Farbe',
    en: 'Footer Color'
  },
  footerTextColor: {
    de: 'Footer Textfarbe',
    en: 'Footer Text Color'
  },
  logoWidth: {
    de: 'Logo Breite',
    en: 'Logo Width'
  },
  bug: {
    de: 'Fehler',
    en: 'Bug'
  },
  improvement: {
    de: 'Verbesserung',
    en: 'Improvement'
  },
  general: {
    de: 'Allgemein',
    en: 'General'
  },
  feedbackSuccessfullySent: {
    de: 'Feedback erfolgreich gesendet.',
    en: 'Feedback successfully sent.'
  },
  yourMessage: {
    de: 'Ihre Nachricht an uns',
    en: 'Your message for us'
  },
  whatCanWeImprove: {
    de: 'Was können wir verbessern?',
    en: 'What can we improve?'
  },
  pleaseDescribeError: {
    de: 'Bitte beschreiben Sie den Fehler.',
    en: 'Please describe the bug.'
  },
  feedbackHint: {
    de: 'Sie haben eine Idee zur Verbesserung, einen Fehler entdeckt oder allgemeines Feedback? Wir freuen uns auf Ihre Nachricht!',
    en: 'Do you have an idea for improvement, discovered a bug, or have general feedback? We look forward to hearing from you!'
  }
}

export default dict
